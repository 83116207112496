// src/components/Skills.js
import React from 'react';
import PropTypes from 'prop-types';
import './Skills.css';

const Skills = ({ skills }) => {
  return (
    <div className="skills">
      <h4 className="skills-title">Skills</h4>
      <div className="skills-list">
        {skills.join(', ')}
      </div>
    </div>
  );
};

Skills.propTypes = {
  skills: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Skills;
