// src/utils/detectTouch.js
function isTouchDevice() {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

if (isTouchDevice()) {
    document.documentElement.classList.add('touch');
} else {
    document.documentElement.classList.add('no-touch');
}
