import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/marketPlace/marketplace-hero1.jpg';

import image1 from '../images/marketPlace/AsiteMarketplacePurchasing-0.jpg';
import image2 from '../images/marketPlace/AsiteMarketplacePurchasing-1.jpg';
import image3 from '../images/marketPlace/AsiteMarketplacePurchasing-2.jpg';
import image4 from '../images/marketPlace/AsiteMarketplacePurchasing-3.jpg';
import image5 from '../images/marketPlace/AsiteMarketplacePurchasing-4.jpg';
import image6 from '../images/marketPlace/AsiteMarketplacePurchasing-5.jpg';
import image7 from '../images/marketPlace/AsiteMarketplacePurchasing-6.jpg';
import image8 from '../images/marketPlace/AsiteMarketplacePurchasing-7.jpg';
import image9 from '../images/marketPlace/AsiteMarketplacePurchasing-8.jpg';


const AsiteMarketplace = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Hero Image 1' },
  ];

  const images = [
    { src: image1, alt: 'Marketplace image 1' },
    { src: image2, alt: 'Marketplace image 2' },
    { src: image3, alt: 'Marketplace image 3' },
    { src: image4, alt: 'Marketplace image 4' },
    { src: image5, alt: 'Marketplace image 5' },
    { src: image6, alt: 'Marketplace image 6' },
    { src: image7, alt: 'Marketplace image 7' },
    { src: image8, alt: 'Marketplace image 8' },
    { src: image9, alt: 'Marketplace image 9' },
  ];


  const skillList = ['Figma', 'UX Design', 'UI Design', 'Prototyping', 'User Research', 'Competitive Analysis', 'Project Management'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h4>Introduction</h4>
        <p>
          The objective of the Asite Marketplace Purchasing project was to streamline the entire procurement process, from product requisition to invoicing, enhancing efficiency, accuracy, and user satisfaction. This comprehensive solution aimed to reduce manual tasks, integrate with third-party systems, and provide robust procurement management tools.
        </p>
        <h4>Research</h4>
        <p>
          <span className='bold'>User Interviews:</span> Conducted with procurement professionals to identify workflows and pain points. Key insights included the need for streamlined automation, ERP integration, and real-time access to supplier data.
        </p>
        <p>
          <span className='bold'>Competitive Analysis:</span> Benchmarked against leading procurement platforms to identify features and improvement areas.
        </p>
        <h4>Design Process</h4>
        <p>
          <span className='bold'>Ideation:</span> Developed concepts to streamline procurement tasks, minimize manual input, and eliminate repetitive processes.
        </p>
        <p>
          <span className='bold'>Prototyping:</span> Created interactive prototypes in Figma, iterating based on user feedback and testing.
        </p>
        <p>
          <span className='bold'>User Testing:</span> Created interactive prototypes in Figma, iterating based on user feedback and testing.
        </p>

        <h4>The Result</h4>

        <ExampleGallery images={images} />

        <p>
          Key achievements;
        </p>

        <ul>
          <li>
            <span className='bold'>End-to-End Procurement Workflow:</span>  Implemented a streamlined process including Product Requisition, Requisition Approval, Supplier Selection, Quotation Management, Order Awarding, Purchase Orders, Delivery Notes, Goods Received Notices, and Invoicing.
          </li>
          <li>
            <span className='bold'>ERP Integration:</span> Enabled seamless data flow between Asite and customers' ERP systems, reducing manual data entry.
          </li>
          <li>
            <span className='bold'>Automated 3-Way Matching:</span> Improved accuracy and reduced administrative workload by automating order, delivery note, and invoice matching.
          </li>
          <li>
            <span className='bold'>Hosted Catalogues:</span> Provided real-time access to product information and pricing through hosted supplier catalogs.
          </li>
          <li>
            <span className='bold'>Increased Revenue:</span> The success of the project led to a significant uptake in the Asite Marketplace suite, contributing to an increase in annual revenue from 4% to 20%.
          </li>

        </ul>

        <h4>Reflection</h4>
        <p>
          <span className='bold'>Challenges:</span>
        </p>
        <ul>
          <li>Balancing comprehensive feature set with user-friendly design.</li>
          <li>Ensuring seamless integration with various ERP systems.</li>
          <li>Managing complex procurement workflows and approval processes.</li>
        </ul>
        <p>
          <span className='bold'>Learnings:</span>
        </p>
        <ul>
          <li>Importance of user-centric design in complex procurement environments.</li>
          <li>Collaboration with stakeholders and cross-functional teams is key to delivering a robust MVP.</li>
          <li>Continuous iteration based on user feedback is crucial for maintaining relevance and satisfaction.</li>
        </ul>

        <h4>Conclusion</h4>
        <p>
        The Asite Marketplace Purchasing platform significantly enhanced procurement efficiency and accuracy, receiving positive feedback from users. The integration with ERP systems and automation of key processes have reduced manual workload and errors, leading to improved business performance.
        </p>

        <h4>Next Steps</h4>
        <p>
          <span className='bold'>Feature Enhancements:</span> Continue iterating on platform features, focusing on data visualization, reporting capabilities, and deeper integration with other Asite products.
        </p>
        <p>
          <span className='bold'>Supplier Relationship Management:</span> Develop advanced tools for managing supplier relationships and performance, enhancing the overall procurement experience.
        </p>

    </PortfolioLayout>
  );
};

export default AsiteMarketplace;
