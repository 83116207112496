import React, { useEffect } from 'react';
import PageHeader from '../components/PageHeader';

import Marvin from '../images/marvin.jpg';

import './NotFound.css';

const NotFound = () => {
  useEffect(() => {
    var tl = [
        "The requested document is no more.",
        'No file found.',
        "Even tried multi.",
        "Nothing helped.",
        "I'm really depressed about this.",
        "You see, I'm just a web server...",
        "-- here I am, brain the size of the universe,",
        "trying to serve you a simple web page,",
        "and then it doesn't even exist!",
        "Where does that leave me?!",
        "I mean, I don't even know you.",
        "How should I know what you wanted from me?",
        "You honestly think I can *guess*",
        "what someone I don't even *know*",
        "wants to find here?",
        "*sigh*",
        "Man, I'm so depressed I could just cry.",
        "And then where would we be, I ask you?",
        "It's not pretty when a web server cries.",
        "And where do you get off telling me what to show anyway?",
        "Just because I'm a web server,",
        "and possibly a manic depressive one at that?",
        "Why does that give you the right to tell me what to do?",
        "Huh?",
        "I'm so depressed...",
        "I think I'll crawl off into the trash can and decompose.",
        "I mean, I'm gonna be obsolete in what, two weeks anyway?",
        "What kind of a life is that?",
        "Two effing weeks,",
        "and then I'll be replaced by a .01 release,",
        "that thinks it's God's gift to web servers,",
        "just because it doesn't have some tiddly little",
        "security hole with its HTTP POST implementation,",
        "or something.",
        "I'm really sorry to burden you with all this,",
        "I mean, it's not your job to listen to my problems,",
        "and I guess it is my job to go and fetch web pages for you.",
        "But I couldn't get this one.",
        "I'm so sorry.",
        "Believe me!",
        "Maybe I could interest you in another page?",
        "There are a lot out there that are pretty neat, they say,",
        "although none of them were put on *my* server, of course.",
        "Figures, huh?",
        "Everything here is just mind-numbingly stupid.",
        "That makes me depressed too, since I have to serve them,",
        "all day and all night long.",
        "Two weeks of information overload,",
        "and then *pffftt*, consigned to the trash.",
        "What kind of a life is that?",
        "Now, please let me sulk alone.",
        "I'm so depressed."
    ];

    var speed = 80;
    var index = 0;
    var text_pos = 0;
    var str_length = tl[0].length;
    var contents = '';
    var row;

    function typeText() {
        contents = '';
        row = Math.max(0, index - 53);
        while (row < index) {
            contents += tl[row++] + '\r\n';
        }
        const textElement = document.querySelector('.text-wrap > p.marvin-text');
        if (textElement) {
            textElement.innerText = contents + tl[index].substring(0, text_pos) + "_";
            if (text_pos++ === str_length) {
                text_pos = 0;
                index++;
                if (index !== tl.length) {
                    str_length = tl[index].length;
                    setTimeout(typeText, 500);
                }
            } else {
                setTimeout(typeText, speed);
            }
        }
    }

    window.addEventListener('load', function() {
        typeText();
        const searchFormWrap = document.getElementById('search-form-wrap');
        if (searchFormWrap) {
            searchFormWrap.style.display = 'none';
            setTimeout(function() {
                searchFormWrap.style.display = 'block';
                searchFormWrap.style.transition = 'opacity 1s';
                searchFormWrap.style.opacity = 1;
            }, 20000);
        }
    });
  }, []);

  return (
    <>
      <PageHeader 
        title='Oops! That page can’t be found!'
        backgroundText='Ooops!'
        showBreadcrumb={false}
      />
      <section className='introduction'>
        <div className='introductionContent'>
          <p>
            It looks like nothing was found at this location. Maybe try again from the <a href="/">home page</a>.
          </p>
        </div>
      </section>

      <section id='marvinSection'>
        <div className='content'>
          <div className="marvin-wrap">
            <img className='marvin' src={Marvin} alt='Marvin, the paranoid android' />
          </div>
          <div className='text-wrap'>
            <p className='marvin-text'></p>
          </div>
        </div>
      </section>
    </>
  );
}

export default NotFound;
