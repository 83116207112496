import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/illegalAliens/Wonderwoman.jpg';
import heroImage2 from '../images/illegalAliens/Captain-America.jpg';

import alienImage1 from '../images/illegalAliens/Vader-Front.jpg';
import alienImage2 from '../images/illegalAliens/Luke-Stromtrooper-Front.jpg';
import alienImage3 from '../images/illegalAliens/Batman-Front.jpg';
import alienImage4 from '../images/illegalAliens/Robin-Front.jpg';
import alienImage5 from '../images/illegalAliens/Robin-Back.jpg';
import alienImage6 from '../images/illegalAliens/The-Joker.jpg';
import alienImage7 from '../images/illegalAliens/Superman.jpg';
import alienImage8 from '../images/illegalAliens/Aquaman-Front.jpg';
import alienImage9 from '../images/illegalAliens/Aquaman-Back.jpg';
import alienImage10 from '../images/illegalAliens/Flash.jpg';
import alienImage11 from '../images/illegalAliens/Jay-Garrek.jpg';
import alienImage12 from '../images/illegalAliens/Green-Lantern.jpg';
import alienImage13 from '../images/illegalAliens/Captain-America-Port.jpg';
import alienImage14 from '../images/illegalAliens/Ironman-Front.jpg';
import alienImage15 from '../images/illegalAliens/Egbert-Front.jpg';

const IllegalAliensCharacters = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Hero Image 1' },
    { src: heroImage2, alt: 'Hero Image 2' }
  ];

  const galleryImages = [
    { src: alienImage1, alt: 'Vader Front' },
    { src: alienImage2, alt: 'Luke Stromtrooper Front' },
    { src: alienImage3, alt: 'Batman Front' },
    { src: alienImage4, alt: 'Robin Front' },
    { src: alienImage5, alt: 'Robin Back' },
    { src: alienImage6, alt: 'The Joker' },
    { src: alienImage7, alt: 'Superman' },
    { src: alienImage8, alt: 'Aquaman Front' },
    { src: alienImage9, alt: 'Aquaman Back' },
    { src: alienImage10, alt: 'Flash' },
    { src: alienImage11, alt: 'Jay Garrek' },
    { src: alienImage12, alt: 'Green Lantern' },
    { src: alienImage13, alt: 'Captain America Port' },
    { src: alienImage14, alt: 'Ironman Front' },
    { src: alienImage15, alt: 'Egbert Front' }
  ];

  const skillList = ['Sketching', 'Illustrator', '3DS Max', 'Character Development', '3D Modelling', 'Texturing'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
      <h4>Overview</h4>
      <p>
      The "Illegal Aliens Character Development" project was a fun and creative endeavor that showcased my skills in 3D modeling, texturing, and rendering. Having previously worked with the client on their website, they approached me to help develop a series of character designs based on their initial sketches and outlines.
      </p>
      <h4>Key Achievements</h4>
      <ul>
        <li><span className='bold'>3D Character Development:</span> Instead of traditional illustrations, I proposed developing the characters in 3D to provide a more realistic and versatile portrayal of the final product. This approach allowed for more detailed and dynamic character designs.</li>
        <li><span className='bold'>Base Models Creation:</span> Utilized 3D Studio Max to create base models for a male and female alien. These base models served as the foundation for developing all the individual characters, ensuring consistency and efficiency in the modeling process.</li>
      </ul>

      <h4>Key Features</h4>
      <h5>Character Modeling:</h5>
      <ul>
        <li><span className='bold'>Initial Sketches and Outlines:</span> Started with client-provided sketches and outlines to create the basic structure of the characters.</li>
        <li><span className='bold'>3D Studio Max:</span> Used this software to model the characters, focusing on achieving a high level of detail and accuracy.</li>
      </ul>
      <h5>Texturing and Rendering:</h5>
      <ul>
        <li><span className='bold'>Photoshop:</span> Created detailed textures to enhance the visual appeal and realism of the characters.</li>
        <li><span className='bold'>Mental Ray:</span> Utilized this industry-leading rendering software to produce high-quality final renders of the characters.</li>
      </ul>

      <h4>3D Character Development</h4>
      <ExampleGallery images={galleryImages} />

      <h4>My Approach</h4>
      <ul>
        <li><span className='bold'>Research and Initial Concepts:</span> Worked closely with the client to understand their vision for each character. This involved reviewing sketches and reference material to ensure the designs met their expectations.</li>
        <li><span className='bold'>3D Modeling and Feedback:</span> Developed initial 3D models of the characters and shared them with the client for feedback. Incorporated their suggestions to refine and improve the models.</li>
        <li><span className='bold'>Texturing and Rendering:</span> Applied textures to the models to enhance their appearance and realism. Used Mental Ray to render high-quality images of the characters from different angles.</li>
      </ul>

      <h4>Conclusion</h4> 
      <p>
      The Illegal Aliens Character Development project highlights my ability to transform client ideas into detailed and realistic 3D models. By leveraging my skills in 3D Studio Max, Photoshop, and Mental Ray, I was able to create visually compelling characters that met the client’s vision and expectations. This project underscores my versatility and creativity, demonstrating my capability to bring characters to life in a dynamic and engaging way.
      </p>
      
      
    </PortfolioLayout>
  );
};

export default IllegalAliensCharacters

