// src/components/PortfolioThumb.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PortfolioThumb.css';

const PortfolioThumb = ({
  image = '../images/pageThumbnails/image-unavailable.png',
  title = 'Holding Title',
  categories = ['UX Design', 'UI Design'],
  link = '#',
  description = 'Troy Hallidays portfolio item'
}) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault();
    navigate(link);
  };

  return (
    <div className="exampleThumb" style={{ backgroundImage: `url(${image})` }}>
      <a href={link} onClick={handleClick} alt={description}>
        <div className="exampleInfo">
          <h3 className='exampleTitle'>{title}</h3>
          <div className='exampleCategories'>{categories.join(' - ')}</div>
        </div>
      </a>
    </div>
  );
};

export default PortfolioThumb;
