import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/3DRepoClash/BatchClash.gif';
import heroImage2 from '../images/3DRepo2D/3DRepo2DScreeens1.jpg';

import galleryImage1 from '../images/3DRepoClash/Clash 1.jpg';
import galleryImage2 from '../images/3DRepoClash/Clash 2.jpg';
import galleryImage3 from '../images/3DRepoClash/Clash 3.jpg';
import galleryImage4 from '../images/3DRepoClash/Clash 4.jpg';
import galleryImage5 from '../images/3DRepoClash/Clash 5.jpg';
import galleryImage6 from '../images/3DRepoClash/Clash 6.jpg';


const RepoEvo = () => {
    const heroImages = [
  
      { src: heroImage2, alt: 'Batch Clash' },
    ];

    const galleryImages = [
        { src: galleryImage1, alt: 'Start' },
        { src: galleryImage2, alt: 'Select Sync Action' },
        { src: galleryImage3, alt: 'Sync to 3D Repo' },
        { src: galleryImage5, alt: 'Sync to Navisworks' },
        { src: galleryImage4, alt: 'Success' },
        { src: galleryImage6, alt: 'Error' },
    ];

    const galleryImages2 = [
        { src: heroImage1, alt: 'Batch Clash' }
    ];

    return (
        <PortfolioLayout heroImages={heroImages} >
            <h4>Overview</h4>
            <p>
            The 3D Repo Clash Detection project was initiated in response to multiple client requests for an enhanced project management system (PMS) that integrated seamlessly with Asite's existing Common Data Environment (CDE). The goal was to enable Project Information Managers to configure their information delivery processes and schedules efficiently, facilitating instant collaboration with colleagues and partners.This section highlights my work on researching and designing a plugin for 3D Repo that imports clash detections from Navisworks, synchronizing them as tasks in 3D Repo. The plugin enhances real-time collaboration and streamlines workflow between Navisworks and 3D Repo, making clash detection and management more efficient for BIM projects. 
            </p>

            <ExampleGallery images={galleryImages2} />

            <h4>Key Achievements</h4>
            <ul>
                <li><span className='bold'>Seamless Integration:</span> Collaborated closely with stakeholders, clients, and developers to ensure seamless integration of clash detections from Navisworks into 3D Repo. This integration ensures that all identified clashes are accurately transferred and categorized as tasks in 3D Repo.</li>
                <li><span className='bold'>Real-Time Synchronization:</span> Designed features for real-time synchronization to keep task statuses and details up-to-date between Navisworks and 3D Repo. Any changes made in either platform are automatically reflected, ensuring all stakeholders have the latest information.</li>
                <li><span className='bold'>User-Friendly Interface:</span> Developed an intuitive interface within 3D Repo for managing imported tasks. Users can easily view, assign, and track the progress of clash detection tasks, facilitating efficient resolution and collaboration.</li>
            </ul>

            <h4>Key Features</h4>
            <h5>Clash Detection Import:</h5>
            <ul>
                <li>Imports clash detections from Navisworks into 3D Repo as actionable tasks.</li>
                <li>Ensures accurate transfer of clash details, including location, type, and severity.</li>
            </ul>
            <h5>Task Management</h5>
            <ul>
                <li>Allows users to assign, prioritize, and track clash detection tasks within 3D Repo.</li>
                <li>Provides a clear overview of task statuses, deadlines, and responsible team members.</li>
            </ul>
            <h5>Real-Time Synchronization</h5>
            <ul>
                <li>Automatically updates task statuses and details between Navisworks and 3D Repo.</li>
                <li>Allows for commenting and discussion directly on tasks, streamlining the resolution process.</li>
            </ul>
            <ExampleGallery images={galleryImages} />
            <h4>My Approach</h4>
            <ul>
                <li><span className='bold'>Research and Ideation:</span> Conducted extensive research on the workflows and pain points associated with using Navisworks and 3D Repo independently. Identified the need for seamless integration to enhance efficiency and reduce manual data transfer efforts.</li>
                <li><span className='bold'>Workshops and Iterative Design:</span> Led several workshops with stakeholders, clients, and developers to gather requirements and feedback. The design process was iterative, with multiple rounds of wireframing, prototyping, and user testing to refine the plugin’s interface and functionalities.</li>
                <li><span className='bold'>Collaborative Development:</span> Worked closely with developers to ensure the plugin’s seamless integration with both Navisworks and 3D Repo. Provided detailed design specifications and conducted regular reviews to maintain alignment with project goals.</li>
                <li><span className='bold'>Stakeholder Engagement:</span> Maintained continuous communication with stakeholders and clients to ensure the design met their needs and expectations. Their feedback was crucial in refining the design and ensuring it delivered value to end users.</li>
            </ul>
            <h4>Conclusion</h4>
            <p>
            By enabling seamless import of clash detections and real-time synchronization, the plugin simplifies task management and improves communication among project stakeholders. This feature was introduced in product release V5.10 along with additional enhancements to the 3D Repo platform. The successful integration of Navisworks and 3D Repo has received positive feedback from users, who appreciate the streamlined workflow and enhanced collaboration capabilities. The plugin has significantly improved the efficiency of clash detection and resolution processes, making it an essential tool for BIM projects.
            </p>

        </PortfolioLayout>
    );
};

export default RepoEvo;