import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage2 from '../images/3DRepo2D/3DRepo2DScreeens1.jpg';


import calibrationImage1 from '../images/3DRepo2D/Calibration1.jpg';
import calibrationImage2 from '../images/3DRepo2D/Calibration2.jpg';
import calibrationImage3 from '../images/3DRepo2D/Calibration3.jpg';
import calibrationImage4 from '../images/3DRepo2D/Calibration4.jpg';
import calibrationImage5 from '../images/3DRepo2D/Calibration5.jpg';
import calibrationImage6 from '../images/3DRepo2D/Calibration6.jpg';

import markupImage1 from '../images/3DRepo2D/2D Markup.jpg';
import markupImage2 from '../images/3DRepo2D/2D Markup 2.jpg';
import markupImage3 from '../images/3DRepo2D/2D Markup 3.jpg';
import markupImage4 from '../images/3DRepo2D/2D Markup 4.jpg';
import markupImage5 from '../images/3DRepo2D/2D Markup 5.jpg';
import markupImage6 from '../images/3DRepo2D/2D Markup 6.jpg';

const Repo2D3D = () => {
  const heroImages = [

    { src: heroImage2, alt: 'wi-Q App Screens' },
  ];
   
  const appImages = [
    { src: calibrationImage1, alt: 'Calibration Flow' },
    { src: calibrationImage2, alt: 'Calibration Flow' },
    { src: calibrationImage3, alt: 'Calibration Flow' },
    { src: calibrationImage4, alt: 'Calibration Flow' },
    { src: calibrationImage5, alt: 'Calibration Flow' },
    { src: calibrationImage6, alt: 'Calibration Flow' }
  ];

    const markupImages = [
    { src: markupImage1, alt: '2D Markup' },
    { src: markupImage2, alt: '2D Markup' },
    { src: markupImage3, alt: '2D Markup' },
    { src: markupImage4, alt: '2D Markup' },
    { src: markupImage5, alt: '2D Markup' },
    { src: markupImage6, alt: '2D Markup' }

    ];


  return (
    <PortfolioLayout heroImages={heroImages}>
        <h4>Overview</h4>
        <p>
            This section showcases my work on designing the 2D/3D integration feature for 3D Repo. This project was a key part of our roadmap, aimed at enhancing the platform’s capabilities by allowing users to interact seamlessly with both 2D drawings and 3D models. The project involved close collaboration with project managers, stakeholders, developers, and clients to ensure the feature met user needs and industry standards.
        </p>

        <h4>3D Repo 2D/3D Integration</h4>
        <p className='bold'>Calibration flow:</p>

        <ExampleGallery images={appImages} />

        <h4>Key Achievements</h4>
        <h5>Client-Centric Design Process:</h5>
        <p>
            We initiated the project by conducting a series of meetings with project managers and stakeholders to outline the feature requirements. This was followed by comprehensive research and the creation of initial proof-of-concept (POC) designs. Before presenting these designs to clients, we conducted interviews to gather their desired functionalities, ensuring our designs were client-focused and addressed real-world needs.
        </p>

        <h5>Iterative Development and Feedback Integration:</h5>
        <p>
            Feedback from client interviews was meticulously collected and analyzed. The overlap in requirements was greater than the disparities, which informed the refinement of our designs. This iterative process, involving multiple rounds of feedback and enhancements, ensured that the final design was robust and aligned with client expectations.  
        </p>
        
        <h5>Collaborative Roadmap and MVP Definition:</h5>
        <p>
            Collaborated closely with the project manager and developers to define a development roadmap and establish the Minimum Viable Product (MVP) criteria. The MVP was structured into multiple milestones, each delivering an operationally functional result that progressively advanced towards the full feature set.
        </p>

        <p className='bold'>2D Markup:</p>
        <ExampleGallery images={markupImages} />


        <h4>My Approach</h4>
        <h5>Research and Initial Design:</h5>
        <p>
        Initiated the project with thorough research on existing 2D/3D integration practices and client requirements. Created initial POC designs based on the research findings.
        </p>
        <h5>Client Workshops and Feedback:</h5>
        <p>
        Conducted workshops with clients to validate the initial designs. Feedback was used to refine and enhance the feature set, ensuring alignment with client needs.
        </p>
        <h5>Collaborative Development Planning:</h5>
        <p>
        Worked with the project manager and developers to outline a detailed development roadmap. Defined milestones and MVP criteria to ensure phased, functional deliveries.  
        </p>

        <h4>What's Next</h4>
        <p>
        The 2D/3D integration feature is currently in development, with the first milestone set to be delivered in Q2 2024. The feature will be rolled out to a select group of clients for beta testing, with a full release planned shortly after. Future enhancements will focus on expanding the feature set based on user feedback of which many of the designs have already been completed.
        </p>

        <h4>Conclusion</h4>
        <p>
            The 2D/3D integration feature for 3D Repo demonstrates my capability to lead a client-centric design process, conduct thorough research, and collaborate effectively with stakeholders and developers. By defining clear milestones and iterating based on client feedback, we were able to create a feature that significantly enhances the platform’s functionality and user experience. This project underscores my skills in strategic planning, user-focused design, and confident presentation to clients and stakeholders.
        </p>

    </PortfolioLayout>
  );
};

export default Repo2D3D
