import { useState, useEffect } from 'react';

const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const checkIfTouchDevice = () => {
      if (window.matchMedia("(pointer: coarse)").matches || 'ontouchstart' in window) {
        setIsTouchDevice(true);
      } else {
        setIsTouchDevice(false);
      }
    };

    checkIfTouchDevice();
  }, []);

  return isTouchDevice;
};

export default useIsTouchDevice;
