import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/illegalAliens/Illegal-Aliens-Website-Mockup.jpg';
import heroImage2 from '../images/illegalAliens/website-mockups.jpg';

import brandingImage1 from '../images/illegalAliens/Branding.png';

const IllegalAliensBranding = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Hero Image 1' },
    { src: heroImage2, alt: 'Hero Image 2' }
  ];

  const brandingImages = [
    { src: brandingImage1, alt: 'Illegal Aliens Branding' }
  ];

  const skillList = ['Figma', 'Illustrator', 'Concept Development', 'Colour Theory', 'Typography', 'Brand Stratergy', 'Market Research'];

  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList} >
      <h4>Overview</h4>
      <p>
      The "Illegal Aliens Branding and Website Design" project was a creative and engaging endeavor that combined branding, web design, and e-commerce integration to create a comic book-inspired online presence. The goal was to design a blog and e-commerce site that visually captured the essence of comic books, providing a dynamic platform for fans and customers.      
      </p>
      <h4>Key Achievements</h4>
      <ul>
        <li><span className='bold'>Comic Book Aesthetic:</span> Designed the website to mimic the look and feel of a comic book, utilizing bold colors, dynamic layouts, and panel-like sections to create an immersive experience for visitors.</li>
        <li><span className='bold'>Brand Identity:</span> Developed a cohesive brand identity that included logo design, color schemes, and typography, all aligned with the comic book theme. This ensured a consistent and recognizable brand presence across all platforms.</li>
      </ul>
      <h4>Key Features</h4>
      <h5>Blog Section:</h5>
      <ul>
        <li><span className='bold'>Engaging Layouts:</span> Created blog layouts that resemble comic book panels, enhancing the reading experience with visually appealing designs.</li>
      </ul>
      <h5>E-Commerce Integration:</h5>
      <ul>
        <li><span className='bold'>User-Friendly Interface:</span> Designed a straightforward and intuitive e-commerce interface, allowing users to browse and purchase products with ease.</li>
        <li><span className='bold'>Comic Book Style Product Pages:</span> Product pages were designed to look like comic book covers, making the shopping experience fun and visually appealing.</li>
        <li><span className='bold'>Seamless Checkout Process:</span> Integrated a secure and efficient checkout process, ensuring a smooth transaction for customers.</li>
      </ul>
      <ExampleGallery images={brandingImages} />
      <h4>My Approach</h4>
      <ul>
        <li><span className='bold'>Research and Concept Development:</span> Conducted extensive research into comic book design elements to ensure the website captured the intended aesthetic. Created initial sketches and wireframes to visualize the layout and design.</li>
        <li><span className='bold'>Branding and Visual Design:</span> Developed a complete branding package, including logo design, color palette, and typography. Ensured all elements were cohesive and represented the comic book theme effectively.</li>
        <li><span className='bold'>Website Development:</span>This is another project that I developed myself, ensuring the website was responsive and functional across all devices. Integrated blog and e-commerce functionalities seamlessly into the comic book style layout.</li>
        <li><span className='bold'>User Testing and Feedback:</span> Conducted user testing to gather feedback on the design and functionality of the website. Made iterative improvements based on user insights to enhance the overall user experience.</li>
      </ul>

      <h4>Conclusion</h4> 
      <p>
      By blending comic book aesthetics with modern web design principles, I developed a blog and e-commerce site that is both visually appealing and highly functional. This project highlights my skills in branding, visual design, and user-centered web development.
      </p>


    </PortfolioLayout>
  );
};

export default IllegalAliensBranding

