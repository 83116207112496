import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = ({ toggleMenu, categories }) => {

  const formattedCategory = (category) => {
    return category.replace(/\s+/g, '-'); // Replace hyphens with spaces
  }


  const handleClick = () => {
    toggleMenu();
    document.getElementsByClassName('page')[0].scrollTo(0, 0);
  };

  return (
    <div className="menu">
      <nav>
        <ul>
          <li>
            <Link to="/" onClick={handleClick}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={handleClick}>
              About Me
            </Link>
          </li>
          {categories.map((category, index) => (
            <li key={index}>
              <Link to={`/${formattedCategory(category)}`} onClick={handleClick}>
                {category}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default Menu;
