import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/gunsForSale/gunsForSale2.jpg';
import heroImage2 from '../images/gunsForSale/GunsForSalePages.jpg';


import galleryImage1 from '../images/gunsForSale/gunsForSale1.jpg';
import galleryImage2 from '../images/gunsForSale/gunsForSale3.jpg';
import galleryImage3 from '../images/gunsForSale/gunsForSale4.jpg';
import galleryImage4 from '../images/gunsForSale/GunsForSaleBranding.png';


const GunsForSale = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Guns For Sale' },
    { src: heroImage2, alt: 'Guns For Sale' },
  ];

  const galleryImages = [
    { src: galleryImage1, alt: 'Guns For Sale' },
    { src: galleryImage2, alt: 'Guns For Sale' },
    { src: galleryImage3, alt: 'Guns For Sale' },

  ];

  const galleryImages2 = [
    { src: galleryImage4, alt: 'Guns For Sale' },
  ];

  const skillList = ['Figma', 'Illustrator', 'Concept Development', 'Colour Theory', 'Typography', 'Brand Stratergy', 'Market Research'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h5>Overview</h5>
        <p>
        This section highlights my work on designing and developing GunsForSale, a mobile-optimized marketplace for buying and selling firearms. The platform complies with legal requirements and leverages existing procedures for secure firearm shipping and tracking through registered gun shops.
        </p>
        <ExampleGallery images={galleryImages2} />
        <ExampleGallery images={galleryImages} />
        <h4>Key Achievments</h4>
        <ul>
            <li><span className='bold'>Compliance and Security:</span> Designed to meet all legal requirements for firearm transactions and verification of buyer and seller credentials integrated into the platform.</li>
            <li><span className='bold'>Responsive Design:</span> Ensures optimal viewing on all devices, from desktops to smartphones.</li>
            <li><span className='bold'>User Experience:</span> Intuitive interface and user-friendly design for easy navigation and seamless transactions.</li>
            <li><span className='bold'>Dealer API Integration:</span> Pulls inventory directly from dealer APIs, ensuring up-to-date listings.</li>
        </ul>

        <h4>My Approach</h4>
        <h5>Research and Ideation</h5>
        <ul>
            <li>Conducted market research to understand the needs of gun enthusiasts.</li>
            <li>Studied existing platforms and consulted with legal experts.</li>
        </ul>
        <h5>Design and Development</h5>
        <ul>
            <li>Created wireframes and prototypes to test user flows.</li>
            <li>Developed a responsive design that adapts to various screen sizes.</li>
            <li>Integrated dealer APIs to pull inventory directly from gun shops.</li>
            <li>Implemented secure payment gateways and user verification processes.</li>
            <li>Conducted user testing to refine the platform and ensure a seamless experience.</li>
        </ul>

        <h4>Conclusion</h4>
        <p>
        The GunsForSale project demonstrates my ability to create a secure, compliant, and user-friendly platform. The website enhances the transaction process through responsive design, advanced functionalities, dealer API integration, and secure shipment processes, providing a seamless experience for users.
        </p>
    </PortfolioLayout>
  );
};

export default GunsForSale
