import React from 'react';
import PageHeader from '../components/PageHeader';
import ProfileImage from '../images/profile-pic.png';
import Button from '../components/Button';
import './About.css';

const About = () => {

  return (
    <>
    <section id='about'>
        <div className='content'>
          <img className='profilePicture' src={ProfileImage} alt='Troy Halliday' />
          <div className='aboutMe'>
            <h2 className='sectionTitle'>About Me</h2>
            <p>
              As a Lead Product Designer with over two decades of experience, I have a proven track record in leadership roles, including Head of Product Design and Principal Product Designer. I excel at delivering innovative designs that exceed client expectations and drive business growth. My career has been dedicated to leading cross-functional teams from ideation to launch, ensuring every product aligns with business goals, user needs, and technological feasibility.
            </p>
            <h3>My design philosophy</h3>
            <h4>User-centric and business-focused:</h4>
            <p>
            I blend a user-centric approach with a keen eye for business opportunities, creating designs that delight users and drive growth. Leveraging user research and design thinking, I develop intuitive products that solve real problems and open new avenues for business success.            </p>
            <h4>Innovation and creativity:</h4>
            <p>
              I am dedicated to advancing design by staying updated with the latest products and trends in the industry. Over the years, I have witnessed transformative changes in technology, which I follow with great interest. The emerging fields of AR and VR especially capture my imagination, and I am eager to explore them.
            </p>
            <h4>Leadership and collaboration:</h4>
            <p>
            I am deeply committed to fostering a collaborative and inclusive environment where every team member feels valued and empowered to contribute their best ideas. By investing in the growth and development of my team, I ensure that creativity thrives and each individual reaches their full potential. My approach to leadership is centered on empathy, mentorship, and creating opportunities for continuous learning and improvement. This not only leads to exceptional work but also cultivates a sense of fulfillment and accomplishment within the team.
            </p>
            <h4>Strategic and Analytical:</h4>
            <p>
            With an analytical nature, I thrive on turning complex issues into simple solutions. My designs are driven by data and strategic insight, ensuring they not only look great but also function seamlessly and meet business objectives. I have a track record of spotting and cultivating new features or opportunities that enhance the product and promote growth in the company. Throughout my career, I have been instrumental in driving business growth through the development of innovative features and new products. My ability to rapidly create proof-of-concept designs and prototypes complements my strategic vision, enabling me to identify and capitalize on growth opportunities effectively.
            </p>
            <h3>Career Highlights</h3>
            <h4>Transformational leadership:</h4>
            <p>
            Successfully transformed design teams into high-performing units, fostering a culture of continuous improvement and high performance.
            </p>
            <h4>Diverse experience:</h4>
            <p>
            Diverse Experience: Started in print industry with expertise in branding, marketing, magazine, and book design. Transitioned to web design, freelancing for over a decade across various agencies. This diverse experience drove my passion for project ownership, leading to increased product growth and client satisfaction.
            </p>
            <h4>Award-Winning Designs:</h4>
            <p>
            Received industry recognition for excellence in design and user experience throughout my career including 6 industry awards for wi-Q Solutions.
            </p>


          </div>
        </div>
      </section>
      <PageHeader
        id='contact'
        section={true}
        title='Let’s connect'
        backgroundText='Contact Me'
        showBreadcrumb={false}
      />
      <section className='contactDetails'>
        <p>
        I’m excited to bring my experience and passion for design to a forward-thinking organization. If you’re seeking a Principal Product Designer or Head of Product Design with vision, creativity, and strategic insight, let’s connect and explore how we can achieve great things together.
        </p>
          <Button type='link' link='mailto:troy.halliday@gmail.com' startIcon='emailSolid'>troy.halliday@gmail.com</Button>
          <Button type='link' link='https://www.linkedin.com/in/troyhalliday/' startIcon='linkedInSolid'>/troyhalliday</Button>
      </section>      
    </>

  );
};

export default About;