// src/components/PortfolioLayout.js
import React from 'react';
import PageHeader from './PageHeader';
import HeroSection from './HeroSection';
import Skills from '../components/Skills';
import StillInterested from './StillInterested';
import RelatedExamples from './RelatedExamples';
import portfolioData from '../data/portfolioData';
import { useLocation } from 'react-router-dom';

const PortfolioLayout = ({ heroImages, children, skills}) => {
  const location = useLocation();
 // const { categoryName, exampleName } = useParams();
  const currentPath = location.pathname;
  const currentItem = portfolioData.find(item => item.link === currentPath);
  
  return (
    <>
      <PageHeader
        title={currentItem.title}
        subtitle={currentItem.description}
        backgroundText='My Work'
        showBreadcrumb={true}
      />
      <HeroSection images={heroImages} />
      <section className='example'>
        <div className='container'>
          {children}
          {skills &&  <Skills skills={skills} /> }
        </div>
      </section>
      <RelatedExamples currentItemId={currentItem.id} />
      <StillInterested hasButton={true} />
    </>
  );
};

export default PortfolioLayout;
