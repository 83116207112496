import React from 'react';
import { useNavigate } from 'react-router-dom';
import './RelatedExamples.css';
import portfolioData from '../data/portfolioData';
import Icon from './Icon';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const RelatedExamples = ({ currentItemId }) => {
  const currentItem = portfolioData.find(item => item.id === currentItemId);
  const navigate = useNavigate();

  if (!currentItem) return null;

  const relatedItems = portfolioData.filter(
    item =>
      item.id !== currentItemId &&
      item.categories.some(category => currentItem.categories.includes(category))
  );

  const handleClick = (event, link) => {
    event.preventDefault();
    navigate(link);
    document.getElementsByClassName('page')[0].scrollTo(0, 0);
  };

  return (
    <section className="related">
      <div className="relatedHeader">
        <h3>Related Examples</h3>
        <div className="scrollIndicator">
          <Icon name="related" />
        </div>
      </div>
      <div className="relatedExamplesContainer">
        <Swiper
          loop={true}
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            769: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1440: {
              slidesPerView: 5,
            },
          }}
        >
          {relatedItems.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="relatedExampleThumb" style={{ backgroundImage: `url(${item.image})` }}>
                <a href={item.link} onClick={(event) => handleClick(event, item.link)} alt={item.description || 'Related Example'}>
                  <div className="relatedExampleInfo">
                    <h3 className='relatedExampleTitle'>{item.title}</h3>
                    <div className='relatedExampleCategories'>{item.categories.join(' - ')}</div>
                  </div>
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default RelatedExamples;
