import React from 'react';
import PageHeader from '../components/PageHeader';
import './Cookies.css';

const CookiesPolicy = () => {

  return (
    <>
      <PageHeader
        title='Cookies Policy'
        subtitle='How we use cookies on this website.'
        backgroundText='Cookies'
        showBreadcrumb={false}
      />
      <section className='cookies'>
        <div className='container'>
          <h1>Cookies Policy</h1>
          <p>Last Updated: 1st August 2024</p>
          <p>
            This Cookies Policy explains how cookies and similar technologies are
            used on my portfolio website.
          </p>

          <h2>1. What Are Cookies?</h2>
          <p>
            Cookies are small text files that are placed on your device when you
            visit a website. They allow the website to recognize your device and
            store certain information about your preferences or past actions.
          </p>

          <h2>2. How I Use Cookies</h2>
          <p>
            I use cookies on this website for the following purposes:
          </p>
          <ul>
            <li>
              <strong>Google Analytics Cookies:</strong> These cookies help me
              understand how visitors use the site. They collect anonymous
              information, such as the number of visitors, the pages visited, and
              how users navigate the site. This data helps me improve the
              website’s functionality and content.
            </li>
          </ul>

          <h2>3. Managing Cookies</h2>
          <p>
            You can manage or disable cookies by adjusting your browser settings.
            However, please note that disabling cookies may affect your ability to
            fully use the website. Below are links to guides on managing cookies in
            popular browsers:
          </p>
          <ul>
            <li><a href="https://support.google.com/chrome/answer/95647">Google Chrome</a></li>
            <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">Mozilla Firefox</a></li>
            <li><a href="https://support.microsoft.com/en-us/microsoft-edge/delete-cookies-in-microsoft-edge-63947406-0401-4c3d-8046-cf949da6a01f">Microsoft Edge</a></li>
          </ul>

          <h2>4. Cookie Consent</h2>
          <p>
            When you visit this website for the first time, you will see a cookie consent banner. You can accept or decline cookies by adjusting your browser settings. 
          </p>

          <h2>5. Changes to This Cookies Policy</h2>
          <p>
            I may update this Cookies Policy from time to time. Any changes will be posted on this page, so please check back regularly.
          </p>

          <h2>6. Contact Information</h2>
          <p>
            If you have any questions or concerns about this Cookies Policy, please
            contact me at
            <a href="mailto:troy.halliday@gmail.com"> troy.halliday@gmail.com</a>.
          </p>
        </div>
      </section>     
    </>
  );
};

export default CookiesPolicy;
