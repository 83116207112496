
/* src/components/ExampleGallery.js */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useCurrentLocation } from '../utils/locationContext';
import { useCookies } from 'react-cookie';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';

import './ExampleGallery.css';
import { Zoom, Navigation, Pagination } from 'swiper/modules';

const ExampleGallery = ({ images }) => {
  const location = useCurrentLocation();
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [cookies] = useCookies(["cookieConsent"]);

  const handleImageClick = (index) => {
     // Trigger GA4 event tracking
    if (window.gtag && cookies.cookieConsent) {
      //console.log('GA4 event:', window.gtag);
      window.gtag('event', 'image_click', {
        event_category: 'Gallery Clicked',
        event_label: `Image ${index + 1} clicked`,
        page_path: location.pathname + location.search,
      });
    }

    setInitialSlide(index);
    setIsGalleryOpen(true);
  };

  const handleCloseGallery = (e) => {
    if (e.target.classList.contains('swiper-gallery-overlay')) {
      setIsGalleryOpen(false);
    }
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  return (
    <div className="example-gallery">
      <div className={`gallery-grid ${images.length === 1 ? 'single-image' : images.length === 2 ? 'two-images' : ''}`}>
        {images.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => handleImageClick(index)}>
            <img src={image.src} alt={image.alt || 'Gallery Image'} />
          </div>
        ))}
      </div>

      {isGalleryOpen && (
        <div className="swiper-gallery-overlay active" onClick={handleCloseGallery}>
          <button className="close-button" onClick={closeGallery}>×</button>
          <Swiper
            initialSlide={initialSlide}
            modules={[Zoom, Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            navigation={true}
            zoom={true}
            pagination={{ clickable: true }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img src={image.src} alt={image.alt || 'Gallery Image'} />
                </div>
                
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default ExampleGallery;

