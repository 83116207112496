import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/portfolioImages/asite-hero-image-1.jpg';
import heroImage2 from '../images/portfolioImages/asite-hero-image-2.jpg';

import fieldImage1 from '../images/portfolioImages/Asite Homepage.jpg';
import fieldImage2 from '../images/portfolioImages/Asite Pin Ballon.jpg';
import fieldImage3 from '../images/portfolioImages/Asite Tasks Sidebar.jpg';
import fieldImage4 from '../images/portfolioImages/Asite Image editing.jpg';

import fieldImage5 from '../images/portfolioImages/Asite Create Multiple Tasks.jpg';
import fieldImage6 from '../images/portfolioImages/Asite Drag Tasks.jpg';  
import fieldImage7 from '../images/portfolioImages/Asite Task.jpg';
import fieldImage8 from '../images/portfolioImages/Asite Field Mobile Screens.jpg';
import fieldImage9 from '../images/portfolioImages/Asite Field Mobile Screens 2.jpg';

import qualityImage1 from '../images/portfolioImages/Asite Quality Plans List.jpg';
import qualityImage2 from '../images/portfolioImages/Asite quality plan.jpg';
import qualityImage3 from '../images/portfolioImages/Asite Quality Overide hold.jpg';
import qualityImage4 from '../images/portfolioImages/Asite Quality Gantt Chart.jpg';
import qualityImage5 from '../images/portfolioImages/Asite Quality Checkbox.jpg';
import qualityImage6 from '../images/portfolioImages/Quality Plans.jpg';

import cbimImages1 from '../images/portfolioImages/Asite cBIM Split view joysticks.jpg';
import cbimImages2 from '../images/portfolioImages/Asite cBIM Task views.jpg';
import cbimImages3 from '../images/portfolioImages/Asite cBIM Mobile Screens.jpg';

import filesImage1 from '../images/portfolioImages/Asite Field Uploads.jpg';
import filesImage2 from '../images/portfolioImages/Asite Files Thumbnails.jpg';
import filesImage3 from '../images/portfolioImages/Asite Field Document.jpg';

const AsiteFieldApp = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Hero Image 1' },
    { src: heroImage2, alt: 'Hero Image 2' }
  ];

  const fieldImages = [
    { src: fieldImage1, alt: 'Field Image 1' },
    { src: fieldImage2, alt: 'Field Image 2' },
    { src: fieldImage3, alt: 'Field Image 3' },
    { src: fieldImage4, alt: 'Field Image 4' },
    { src: fieldImage5, alt: 'Field Image 5' },
    { src: fieldImage6, alt: 'Field Image 6' },
    { src: fieldImage7, alt: 'Field Image 7' },
    { src: fieldImage8, alt: 'Field Image 8' },
    { src: fieldImage9, alt: 'Field Image 9' }
  ];

  const qualityImages = [
    { src: qualityImage1, alt: 'Quality Image 1' },
    { src: qualityImage2, alt: 'Quality Image 2' },
    { src: qualityImage3, alt: 'Quality Image 3' },
    { src: qualityImage4, alt: 'Quality Image 4' },
    { src: qualityImage5, alt: 'Quality Image 5' },
    { src: qualityImage6, alt: 'Quality Image 6' }
  ];

  const cbimImages = [
    { src: cbimImages1, alt: 'cBIM Image 1' },
    { src: cbimImages2, alt: 'cBIM Image 2' },
    { src: cbimImages3, alt: 'cBIM Image 3' }
  ];

  const filesImages = [
    { src: filesImage1, alt: 'Files Image 1' },
    { src: filesImage2, alt: 'Files Image 2' },
    { src: filesImage3, alt: 'Files Image 3' },
  ]

  const skillList = ['Figma', 'UX Design', 'UI Design', 'Prototyping', 'User Research', 'Competitive Analysis', 'Project Management'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h4>Introduction</h4>
        <p>
          In the Asite Field app redesign project, our primary objective was to elevate the UX/UI to achieve significant improvements in time and cost savings, project oversight, and enable paperless workflows. Additionally, we sought to leverage the capabilities of the web platform and integrate enhanced tools such as Quality Plans, Common Data Environment (CDE), and 3D cBIM. By incorporating these additional features, we aimed to further empower users with robust data management, seamless collaboration, and advanced visualisation capabilities, enhancing the overall project management experience.
        </p>
        <h4>Research</h4>
        <p>
          <span className='bold'>User Interviews:</span> Conducted interviews with a cross selection construction professionals, including project managers, site engineers, and field workers to understand their workflows and pain points. Key insights included:
        </p>
        <ul>
          <li>Need for real-time data access and collaboration</li>
          <li>Inefficiencies in defect management and tracking</li>
          <li>Importance of project oversight and compliance</li>
        </ul>
        <p>
          <span className='bold'>Competitive Analysis:</span> Analyzed five competing field management apps to identify industry standards and areas for improvement. This helped in benchmarking features and setting design goals.
        </p>
        <h4>Design Process</h4>
        <p>
          <span className='bold'>Ideation:</span> Brainstormed design concepts and features to address user needs and pain points. This involved sketching wireframes, creating user personas, and defining design goals.
        </p>
        <p>
          <span className='bold'>Prototyping:</span> Developed interactive prototypes using Figma to test and validate design concepts. This iterative process involved user testing, feedback collection, and design refinements.
        </p>
        <p>
          <span className='bold'>User Testing:</span> Conducted usability tests with construction professionals to gather feedback on the app's usability, functionality, and visual design. This feedback was instrumental in refining the app's features and enhancing the user experience.
        </p>

        <h4>The Result</h4>

        <ExampleGallery images={fieldImages} />

        <p>
          Key achievements;
        </p>

        <ul>
          <li>
            <span className='bold'>Redesigned dashboard:</span> A clean and intuitive dashboard that highlights key on-site activities, such as tasks, defects, and inspections.
          </li>
          <li>
            <span className='bold'>Improved Navigation:</span> Simplified navigation structure with clear labels and an easy-to-access menu, including shortcuts for quick task creation.
          </li>
          <li>
            <span className='bold'>Offline Capabilities:</span> Enabled offline mode for accessing project documents and managing tasks without an internet connection.
          </li>
          <li>
            <span className='bold'>Enhanced task management:</span> Streamlined task creation, assignment, and tracking, enabling real-time collaboration and progress monitoring.
          </li>
          <li>
            <span className='bold'>Quality and Safety:</span> Introduced a comprehensive inspection tool with customizable checklists and automated workflows for reporting and resolving issues.
          </li>
        </ul>

        <h4>Reflection</h4>
        <p>
          <span className='bold'>Challenges:</span> Balancing the diverse needs of construction professionals and ensuring robust offline functionality were significant challenges. Continuous user feedback was crucial in refining the app. Balancing development timelines and feature prioritisation was also a key challenge.
        </p>
        <p>
          <span className='bold'>Learnings:</span>
        </p>
        <ul>
          <li>Importance of user-centric design in complex industries like construction.</li>
          <li>Effective communication and collaboration with cross functional teams and stakeholders to bring a robust minimal viable product (MVP) to market. </li>
        </ul>

        <h4>Conclusion</h4>
        <p>
          The redesigned Asite Field app received positive feedback from users and significantly improved on-site efficiency and communication. User engagement increased by 35% within the first three months post-launch.
        </p>

        <h4>Next Steps</h4>
        <p>
          <span className='bold'>Feature Enhancements:</span> Continuously iterate on the app's features based on user feedback and industry trends. Focus on enhancing data visualisation, reporting capabilities, and integration with other Asite products.
        </p>
        <p>
          <span className='bold'>Quality Plan Module:</span> The addition of a Quality Plan module to streamline quality assurance processes and compliance management. This feature has been ported over from the web based CDE platform. The desktop based matrix style view has been adapted to a linear format for mobile devices with the addition of date tracking and ability to overide hold statuses.
        </p>

        <ExampleGallery images={qualityImages} />

        <p>
          <span className='bold'>3D cBIM Module:</span> The 3D cBIM module has been designed and developed however its inclusion into the Asite Field app is in question since the aquisition of 3D Repo which may be developed into a standalone mobile optimised product. 
        </p>

        <ExampleGallery images={cbimImages} />

        <p>
          <span className='bold'>Files & Forms Feature:</span> The addition of a files and forms feature to allow users to upload and download files and forms directly from the app. This feature will be integrated with the Asite CDE platform to allow for seamless data management and collaboration.
        </p>

        <ExampleGallery images={filesImages} />

    </PortfolioLayout>
  );
};

export default AsiteFieldApp;
