// src/components/Icon.js
import React from 'react';
import { ReactComponent as FilterIcon } from '../icons/filter.svg';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import { ReactComponent as NextIcon } from '../icons/next.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous.svg';
import { ReactComponent as LinkedInSolidIcon } from '../icons/linkedin-solid.svg';
import { ReactComponent as EmailSolidIcon } from '../icons/email-solid.svg';
import { ReactComponent as RelatedIcon } from '../icons/related.svg';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as PlayIcon } from '../icons/play.svg';

const icons = {
    close: CloseIcon,
    emailSolid: EmailSolidIcon,
    filter: FilterIcon,
    home: HomeIcon,
    linkedInSolid: LinkedInSolidIcon,
    menu: MenuIcon,
    next: NextIcon,
    previous: PreviousIcon,
    related: RelatedIcon,
    play: PlayIcon
};

const Icon = ({ name, width = 32, height = 32, ...props }) => {
  const IconComponent = icons[name];
  return IconComponent ? <IconComponent width={width} height={height} {...props} /> : null;
};

export default Icon;
