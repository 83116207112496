import React from 'react';
import './PageHeader.css';
import Breadcrumb from './Breadcrumb';
import { useLocation } from 'react-router-dom';

const PageHeader = ({id, style = 'centre',section , title, subtitle, backgroundText, showBreadcrumb, ...props }) => {
  return (
    <section id={id ? id : undefined} className={`pageHeader ${style}`} >
        {showBreadcrumb && (
          <BreadcrumbWithLocation />
        )}
        {section && <h3 className='sectionTitle'>{title}</h3>}
        {!section && <h2 className='pageTitle'>{title}</h2>}
        {subtitle && <h3 className='pageSubtitle'>{subtitle}</h3>}
        {!subtitle && <div className='underline'></div>}
        <div className='backgroundText'>{backgroundText}</div>
    </section>
  );
};

const BreadcrumbWithLocation = () => {
    const location = useLocation();
    return <Breadcrumb path={location.pathname} />;
};

export default PageHeader;
