import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/wiQProduct/wiqProduct1.jpg';
import heroImage2 from '../images/wiQProduct/wiqProduct2.jpg';
import heroImage3 from '../images/wiQProduct/wiqProduct3.jpg';
import heroImage4 from '../images/wiQProduct/wiqProduct4.jpg';
import heroImage5 from '../images/wiQProduct/wiqProduct5.jpg';

import appImage1 from '../images/wiQProduct/wiqProduct6.jpg';
import appImage2 from '../images/wiQProduct/wiqProduct7.jpg';
import appImage3 from '../images/wiQProduct/wiqProduct8.jpg';

import adminImage1 from '../images/wiQProduct/wiqProduct9.jpg';
import adminImage2 from '../images/wiQProduct/wiqProduct10.jpg';
import adminImage3 from '../images/wiQProduct/wiqProduct11.jpg';

import connectImage1 from '../images/wiQProduct/wiqProduct12.jpg';
import connectImage2 from '../images/wiQProduct/wiqProduct13.jpg';
import connectImage3 from '../images/wiQProduct/wiqProduct14.jpg';

import kioskImage1 from '../images/wiQProduct/wiqProduct15.jpg';
import kioskImage2 from '../images/wiQProduct/wiqProduct16.jpg';
import kioskImage3 from '../images/wiQProduct/wiqProduct17.jpg';

const WiqProductDesign = () => {
  const heroImages = [
    { src: heroImage1, alt: 'wi-Q App Screens' },
    { src: heroImage2, alt: 'wi-Q Kiosk' },
    { src: heroImage3, alt: 'wi-Q Admin Desktop' },
    { src: heroImage4, alt: 'wi-Q App Order Status' },
    { src: heroImage5, alt: 'wi-Q App PinkBerry' }
  ];
   
  const appImages = [
    { src: appImage1, alt: 'wi-Q Initial Design' },
    { src: appImage2, alt: 'wi-Q app design by me' },
    { src: appImage3, alt: 'wi-Q Checkout change of address' }
  ];

    const adminImages = [
    { src: adminImage1, alt: 'wi-Q Admin area' },
    { src: adminImage2, alt: 'wi-Q Payment Providers' },
    { src: adminImage3, alt: 'wi-Q Admin setup' }
    ];

    const connectImages = [
    { src: connectImage1, alt: 'wi-Q Connect Tablet' },
    { src: connectImage2, alt: 'wi-Q Connect Mobile' },
    { src: connectImage3, alt: 'wi-Q Order closeup' }
    ];

    const kioskImages = [
    { src: kioskImage1, alt: 'wi-Q Kiosk counterside tablet' },
    { src: kioskImage2, alt: 'wi-Q Kiosk' },
    { src: kioskImage3, alt: 'Shake Shack using wi-Q Kiosk' }
    ];



  return (
    <PortfolioLayout heroImages={heroImages}>
        <h4>Overview</h4>
        <p>
          This section showcases my work as the Lead Product Designer for the wi-Q group of products. From September 2016 to April 2022, I spearheaded the design and development of wi-Q’s digital guest engagement solutions, transforming them from initial concepts into market-leading, award-winning products used in 28 countries.
        </p>

        <h4>wi-Q Mobile Ordering</h4>

        <ExampleGallery images={appImages} />
        <h5>Description:</h5>
        <p>
        The core of wi-Q’s product suite, wi-Q Mobile Ordering, revolutionized the ordering process by allowing guests to place orders directly from their mobile devices. This innovation not only enhanced customer experience but also significantly boosted operational efficiency for hospitality venues.
        </p>
        <h5>Key Features:</h5>
        <ul>
          <li><span className='bold'>User-Friendly Interface:</span> Designed for easy navigation and quick order placement.</li>
          <li><span className='bold'>EPOS Integration:</span> Seamless integration with venue EPOS systems.</li>
          <li><span className='bold'>Customizable Menus:</span> Flexible menu designs tailored to different venue requirements.</li>
        </ul>
     
        <h5>My Role:</h5>
        <p>
        As the Lead Product Designer, I was responsible for the end-to-end design process, from initial research and ideation to wireframing, prototyping, and user testing. My goal was to create an intuitive and visually appealing interface that meets both user needs and business objectives.
        </p>
        <h5>Conclusion:</h5>
        <p>
        The success of wi-Q Mobile Ordering demonstrates my ability to design innovative solutions that enhance user experience and drive business growth. By creating a seamless ordering process, I helped venues improve efficiency and customer satisfaction, leading to increased revenue and customer loyalty.
        </p>

        <h4>wi-Q BackOffice</h4>

        <ExampleGallery images={adminImages} />

        <h5>Description:</h5>
        <p>
        The wi-Q BackOffice serves as the central hub for managing wi-Q’s suite of products. It provides a comprehensive interface for handling menus, products, stock, and live orders, ensuring that venue staff can operate efficiently.
        </p>
        <h5>Key Features:</h5>
        <ul>
          <li><span className='bold'>Menu and Product Management:</span> Easy setup and maintenance of menus and products.</li>
          <li><span className='bold'>Stock and Order Management:</span> Real-time tracking and management of stock and live orders.</li>
          <li><span className='bold'>Integration:</span>  Compatibility with existing EPOS systems for seamless operations.</li>
        </ul>
        <h5>My Role:</h5>
        <p>
        I led the design of the BackOffice system, focusing on creating an intuitive and efficient user interface. This involved detailed user research, wireframing, prototyping, and continuous iteration based on user feedback.
        </p>
        <h5>Conclusion:</h5>
        <p>
        The wi-Q BackOffice project showcases my ability to design complex systems that streamline operations and enhance user experience. By creating a user-friendly interface, I helped venue staff manage their operations more effectively, leading to improved efficiency and customer satisfaction.
        </p>

        <h4>wi-Q Kiosk</h4>
        <ExampleGallery images={kioskImages} />

        <h5>Description:</h5>
        <p>
        The wi-Q Kiosk is a standalone self-service system designed for Quick Service Restaurants (QSRs). It aims to reduce staff costs and increase profits by providing a smart, user-friendly self-service option.
        </p>
        <h5>Key Features:</h5>
        <ul>
          <li><span className='bold'>Self-Service Ordering:</span> Reduces queue times and enhances customer experience.</li>
          <li><span className='bold'>System Integration:</span> Fully integrated with venue systems for seamless operations.</li>
          <li><span className='bold'></span> Adaptable to match the branding of different venues.</li>
        </ul>
        <h5>My Role:</h5>
        <p>
        In this project, I focused on developing a self-service interface that is intuitive and engaging for users. My responsibilities included concept development, wireframing, prototyping, and user testing to refine the design.
        </p>
        <h5>Conclusion:</h5>
        <p>
        The wi-Q Kiosk project highlights my skills in designing self-service solutions that enhance customer experience and operational efficiency.
        </p>

        <h4>wi-Q Connect</h4>
        <ExampleGallery images={connectImages} />
        <h5>Description:</h5>
        <p>
        Wi-Q Connect was developed in response to a unique challenge faced by a customer during the COVID-19 lockdown. To keep their restaurant business afloat, the customer repurposed their kitchens solely for delivery, using multiple last-mile delivery services like Just Eat and Deliveroo. The problem was that their kitchen management software was incompatible with these delivery companies, requiring the use of multiple tablets for each service. Recognizing this inefficiency during a customer interview, I identified an opportunity to create a product that could bridge the gap between the two systems' APIs, leveraging wi-Q's existing integration with their system.
        </p>
        <h5>Key Features:</h5>
        <ul>
          <li><span className='bold'>Centralized Order Management:</span> Manage orders from various delivery partners through a single interface.</li>
          <li><span className='bold'>API Integration:</span> Seamlessly connect kitchen management software with last-mile delivery services.</li>
          <li><span className='bold'>Simplified Operations:</span> Eliminate the need for multiple devices, streamlining order processing and management.</li>
        </ul>
        <h5>My Role:</h5>
        <p>
        I was instrumental in identifying this opportunity from a customer interview and led the design process to develop an intuitive interface that facilitated seamless integration between the kitchen management system and delivery service APIs. My responsibilities included conducting extensive user research, developing detailed wireframes, creating interactive prototypes, and performing usability testing to ensure the solution met user needs.
        </p>
        <h5>Conclusion:</h5>
        <p>
        Wi-Q Connect demonstrates my ability to develop innovative solutions in response to real-world challenges, enhancing operational efficiency and user experience during critical times.
        </p>



    </PortfolioLayout>
  );
};

export default WiqProductDesign
