// src/components/Button.js
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './Button.css';
import Icon from '../components/Icon';

const Button = ({
  type = 'primary',
  size = 'default',
  startIcon = false,
  endIcon = false,
  children,
  onClick,
  link,
  internalLink,
  disabled = false,
  style = {},
}) => {
  const navigate = useNavigate();
  const buttonClass = `button button-${type} button-${size} ${disabled ? 'disabled' : ''}`;

  const handleClick = (event) => {
    if (disabled) return;
    if (link) {
      // Open the link in a new tab
      window.open(link, '_blank', 'noopener,noreferrer');
    } else if (internalLink) {
      navigate(internalLink);
    } else if (onClick) {
      onClick(event);
    }
  };

  return (
    <button className={buttonClass} onClick={handleClick} disabled={disabled} style={style}>
      {startIcon && <Icon name={startIcon} className="button-icon button-icon-start" />}
      {children}
      {endIcon && <Icon name={endIcon} className="button-icon button-icon-end" />}
    </button>
  );
};

Button.propTypes = {
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'destructive', 'outline', 'primaryOutline', 'primary primary-special', 'header', 'link']),
  size: PropTypes.oneOf(['small', 'default', 'large']),
  startIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  endIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  onClick: PropTypes.func,
  link: PropTypes.string,
  internalLink: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
