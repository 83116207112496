// src/components/PortfolioSection.js
import React from 'react';
import PortfolioThumb from './PortfolioThumb';
import './PortfolioSection.css';
import portfolioData from '../data/portfolioData';

const PortfolioSection = ({ category }) => {
  // Filter portfolio data based on the category prop
  const filteredData = category 
    ? portfolioData.filter(item => item.categories.includes(category)) 
    : portfolioData;

  return (
    <section className="portfolio">
      <div className="portfolio-grid">
        {filteredData.map((item) => (
          <PortfolioThumb
            key={item.id}
            image={item.image}
            title={item.title}
            categories={item.categories}
            link={item.link}
            description={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default PortfolioSection;
