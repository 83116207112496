// src/data/portfolioData.js


import asiteFieldAppThumbnail from '../images/pageThumbnails/asite-field-app-thumbnail.jpg';
import asiteDesignsThumbnail from '../images/pageThumbnails/asite-branding-thumbnail.jpg';
import asiteMarketplaceThumbnail from '../images/pageThumbnails/asite-marketplace-thumbnail.jpg';
import illegalAliensBranding from '../images/illegalAliens/Illegal-Aliens-Website-Mockup.jpg';
import illegalAliensCharacters from '../images/illegalAliens/Wonderwoman.jpg';
import illegalAliensIllustrations from '../images/illegalAliens/illegal-aliens-sketch-book.jpg';

import wiQProductDesign from '../images/wiQProduct/wiqProduct1.jpg';
import wiQBranding from '../images/wiQBranding/wi-qBranding1.jpg';
import bmwAndroidHeadunit from '../images/bmwHeadunit/bmwHeadunit1.jpg';
import jobs365 from '../images/jobs365/jobs1.jpg';
import gunsForSale from '../images/gunsForSale/gunsForSale2.jpg';
//import volksfitBranding from '../images/volksfitBranding/volksfitBranding1.jpg';
//import volksfitWebsite from '../images/volksfitWebsite/volksfitWebsite1.jpg';
//import gingerSnaps from '../images/gingerSnaps/gingerSanps1.jpg';
//import c2cNetworkRail from '../images/c2c/c2c-image-1.jpg';
import aStock from '../images/stockManagement/StockManagementMockupLight.jpg';
import Repo2D from '../images/3DRepo2D/3DrepoThumb.jpg';
import asitePlaybook from '../images/asitePlaybook/playbook-thumb.jpg';
import clashMockup from '../images/3DRepoClash/clash-mockup.jpg';  

//import Breadcrumb from '../components/Breadcrumb';


const portfolioData = [

  {
    id: 1,
    title: '3D Repo 2D/3D',
    description: '2D/3D Feature Design for 3D Repo',
    image: Repo2D,
    link: '/3d-repo',
    categories: ['App Design', 'UX Design', 'UI Design'],
    breadcrumb: '3D Repo 2D/3D'
  },
  {
    id: 2,
    title: '3D Repo Clash Detection',
    description: '3D Repo Navisworks Plugin',
    image: clashMockup,
    link: '/3d-repo-clash-detection',
    categories: ['UX Design', 'UI Design'],
    breadcrumb: '3D Repo Clash Detection'
  },
  /*{
    id: 3,
    title: '3D Repo Design System',
    description: 'Evolution of 3D Repo Design System',
    image: asiteFieldAppThumbnail,
    link: '/3d-repo-design-system',
    categories: ['UX Design', 'UI Design'],
    breadcrumb: '3D Repo Design System'
  },*/
  {
    id: 4,
    title: 'Asite Field App',
    description: 'UX/UI Design of Asite Field',
    image: asiteFieldAppThumbnail,
    link: '/asite-field-app',
    categories: ['App Design', 'UX Design', 'UI Design'],
    breadcrumb: 'Asite Field App'
  },
  {
    id: 5,
    title: 'Asite Designs',
    description: 'Logo & Icon designs',
    image: asiteDesignsThumbnail,
    link: '/asite-branding',
    categories: ['Brand Design', 'Logo Design'],
    breadcrumb: 'Asite Designs'
  },
  {
    id: 6,
    title: 'Asite Marketplace',
    description: 'UX/UI Design of Asite Marketplace',
    image: asiteMarketplaceThumbnail,
    link: '/asite-marketplace',
    categories: ['UX Design', 'UI Design'],
    breadcrumb: 'Asite Marketplace'
  },
  {
    id: 7,
    title: 'Asite aStock',
    description: 'Inventory Management System',
    image: aStock,
    link: '/asite-astock',
    categories: ['UI Design', 'UX Design', 'App Design'],
    breadcrumb: 'Asite aStock'
  },
  {
    id: 8,
    title: 'Asite Playbook',
    description: 'Project Management Tool Design',
    image: asitePlaybook,
    link: '/asite-playbook',
    categories: ['UI Design', 'UX Design'],
    breadcrumb: 'Asite Playbook'
  },

  {
    id: 9,
    title: 'wi-Q Product Design',
    description: 'Application design for wi-Q',
    image: wiQProductDesign,
    link: '/wiq-product-design',
    categories: ['App Design', 'UX Design', 'UI Design'],
    breadcrumb: 'wi-Q Product Design'
  },
  {
    id: 10,
    title: 'wi-Q Branding & Marketing',
    description: 'Branding & Marketing for wi-Q',
    image: wiQBranding,
    link: '/wiq-branding',
    categories: ['Brand Design', 'Logo Design', 'Motion Design'],
    breadcrumb: 'wi-Q Branding'
  },
  {
    id: 11,
    title: 'BMW Android Headunit',
    description: 'Android Headunit design for BMWs',
    image: bmwAndroidHeadunit,
    link: '/bmw-android-headunit',
    categories: ['App Design', 'UX Design', 'UI Design'],
    breadcrumb: 'BMW Android Headunit'
  },
  {
    id: 12,
    title: 'Jobs 365',
    description: 'Job search web app design',
    image: jobs365,
    link: '/jobs-365',
    categories: ['App Design', 'UX Design', 'UI Design', 'Web Design'],
    breadcrumb: 'Jobs 365'
  },
  {
    id: 13,
    title: 'Guns for Sale',
    description: 'E-commerce app & branding design',
    image: gunsForSale,
    link: '/guns-for-sale',
    categories: ['App Design', 'Brand Design', 'Logo Design',  'UX Design', 'UI Design'],
    breadcrumb: 'Guns for Sale'
  },
  /*{

    id: 14,
    title: 'Volksfit Branding',
    description: 'Branding for Volksfit',
    image: volksfitBranding,
    link: '/volksfit-branding',
    categories: ['Brand Design', 'Logo Design', 'UI Design'],
    breadcrumb: 'Volksfit Branding'
  },
  {
    id: 15,
    title: 'Volksfit Website',
    description: 'Website design for Volksfit',
    image: volksfitWebsite,
    link: '/volksfit-website',
    categories: ['Web Design', 'UI Design'],
    breadcrumb: 'Volksfit Website'
  },
  {
    id: 16,
    title: 'Ginger Snaps',
    description: 'Branding and Website for Ginger Snaps',
    image: gingerSnaps,
    link: '/ginger-snaps',
    categories: ['Web Design', 'Brand Design', 'Logo Design', 'UI Design'],
    Breadcrumb: 'Ginger Snaps'
  },
  {
    id: 17,
    title: 'c2c Network Rail',
    description: 'c2c Act Card design & Print',
    image: c2cNetworkRail,
    link: '/c2c-network-rail',
    categories: ['Print Design'],
  },*/
  {
    id: 18,
    title: 'Illegal Aliens Branding',
    description: 'Branding for Illegal Aliens',
    image: illegalAliensBranding,
    link: '/illegal-aliens-branding',
    categories: ['Brand Design', 'Logo Design', 'UI Design', 'Web Design'],
    breadcrumb: 'Illegal Aliens Branding'
  },
  {
    id: 19,
    title: 'Illegal Aliens Characters',
    description: 'Character designs for Illegal Aliens',
    image: illegalAliensCharacters,
    link: '/illegal-aliens-characters',
    categories: ['Illustration', '3D Modelling'],
    breadcrumb: 'Illegal Aliens Characters'
  },
  {
    id: 20,
    title: 'Illegal Aliens Illustrations',
    description: 'Illustrations for Illegal Aliens',
    image: illegalAliensIllustrations,
    link: '/illegal-aliens-illustrations',
    categories: ['Illustration'],
    breadcrumb: 'Illegal Aliens Illustrations'
  }

  // Add more projects as needed
];

export default portfolioData;
