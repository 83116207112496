// src/LocationContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const LocationContext = createContext();

// Create a provider component
export const LocationProvider = ({ children }) => {
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);

  useEffect(() => {
    setCurrentLocation(location);
  }, [location]);

  return (
    <LocationContext.Provider value={currentLocation}>
      {children}
    </LocationContext.Provider>
  );
};

// Custom hook to use the location context
export const useCurrentLocation = () => useContext(LocationContext);
