import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/asitePlaybook/heroImage.jpg';

import galleryImage1 from '../images/asitePlaybook/Playbook 1.jpg';
import galleryImage2 from '../images/asitePlaybook/Playbook 2.jpg';
import galleryImage3 from '../images/asitePlaybook/Playbook 3.jpg';
import galleryImage4 from '../images/asitePlaybook/Playbook 4.jpg';
import galleryImage5 from '../images/asitePlaybook/Playbook 5.jpg';
import galleryImage6 from '../images/asitePlaybook/Playbook 6.jpg';
import galleryImage7 from '../images/asitePlaybook/Playbook 7.jpg';
import galleryImage8 from '../images/asitePlaybook/Playbook 8.jpg';
import galleryImage9 from '../images/asitePlaybook/Playbook 9.jpg';

const AsitePlaybook = () => {
    const heroImages = [
        { src: heroImage1, alt: 'Asite Branding' },
    ];

    const galleryImages = [
        { src: galleryImage1, alt: 'Asite Branding' },
        { src: galleryImage2, alt: 'Asite Branding' },
        { src: galleryImage3, alt: 'Asite Branding' },
        { src: galleryImage4, alt: 'Asite Branding' },
        { src: galleryImage5, alt: 'Asite Branding' },
        { src: galleryImage8, alt: 'Asite Branding' },
        { src: galleryImage6, alt: 'Asite Branding' },
        { src: galleryImage7, alt: 'Asite Branding' },
        { src: galleryImage9, alt: 'Asite Branding' },
    ];
    
    const skillList = ['Figma', 'Illustrator', 'Concept Development', 'Colour Theory', 'Typography', 'Brand Stratergy', 'Market Research'];

    return (
    
        <PortfolioLayout heroImages={heroImages} skills={skillList} >
            

            <h4>Overview</h4>
            <p>
            The Asite Playbook project was initiated in response to multiple client requests for an enhanced project management system (PMS) that integrated seamlessly with Asite's existing Common Data Environment (CDE). The goal was to enable Project Information Managers to configure their information delivery processes and schedules efficiently, facilitating instant collaboration with colleagues and partners.
            </p>
            <h4>Key Achievements</h4>
            <ul>
                <li><span className='bold'>Client-Centric Solution:</span> Developed a solution to address clients' needs for a unified data and project management system. The Playbook integrates project management functionalities with Asite's robust CDE capabilities, ensuring all project data is centralized and easily accessible.</li>
                <li><span className='bold'>Dashboard and Interface Design:</span> Designed a comprehensive dashboard and common interface to streamline the numerous tasks associated with project management. This design focused on usability and efficiency, providing users with a clear and intuitive toolset to manage their projects.</li>
            </ul>
            <ExampleGallery images={galleryImages} />
            <h4>Key Features</h4>

            <h5>Information Delivery Processes:</h5>
            <ul>
                <li><span className='bold'>Configurable Processes and Schedules: </span>Allows Project Information Managers to set up and manage their information delivery workflows.</li>
                <li><span className='bold'>Centralized Data Management:</span> Ensures all project data is stored in one place, eliminating the inefficiencies of having data spread across multiple systems.</li>
            </ul>

            <h5>Collaboration Tools:</h5>
            <ul>
                <li><span className='bold'>Integrated Files and Forms:</span> Utilizes Asite’s existing collaboration tools to enhance communication and data sharing among project stakeholders.</li>
                <li><span className='bold'>Instant Collaboration:</span> Facilitates real-time collaboration with colleagues and partners, ensuring everyone is on the same page.</li>
            </ul>

            <h5>Dashboard and Task Management:</h5>
            <ul>
                <li><span className='bold'>Unified Dashboard:</span> Provides a single interface to manage all project tasks, enhancing visibility and control over project activities.</li>
                <li><span className='bold'>User-Friendly Design:</span>  Focuses on ease of use, ensuring that even complex project management tasks can be handled efficiently.</li>
            </ul>

            <h4>My Approach</h4>
            <ul>
                <li><span className='bold'>Client Interviews and Feature List:</span>The project began with client interviews to understand their specific needs and challenges. This led to the creation of a detailed list of required features that would solve the problem of data fragmentation and underperforming management systems.</li>
                <li><span className='bold'>Research and Design:</span> Conducted thorough research to identify the best ways to integrate Asite’s CDE with advanced project management functionalities. Designed initial POC (Proof of Concept) based on this research, ensuring it met all identified requirements.</li>
                <li><span className='bold'>Presentation and Approval:</span> Presented the POC designs to the CEO and shareholders, highlighting the potential benefits and strategic value of the Asite Playbook. Secured approval to proceed with the project, which was greenlit for development.</li>
                <li><span className='bold'>Development and Iteration:</span> Collaborated with developers to create an MVP (Minimum Viable Product). Continued to refine the design and functionality based on ongoing feedback, bringing the Playbook closer to the original concept with each iteration.</li> 
            </ul>

            <h4>Conclusion</h4>
            <p>
            The Asite Playbook project demonstrates my ability to identify client needs, design innovative solutions, and collaborate effectively with stakeholders and development teams. By integrating project management features with Asite's CDE, the Playbook enhances data centralization and project efficiency, providing a valuable tool for Project Information Managers. This project highlights my skills in client interaction, research, design, and iterative development.
            </p>
        
        </PortfolioLayout>
    );
};

export default AsitePlaybook;