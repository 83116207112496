import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import portfolioData from '../data/portfolioData';
import './Breadcrumb.css'; // Import a CSS file for styling

const Breadcrumb = () => {
  const location = useLocation();
  const pathArray = location.pathname.split('/').filter(i => i);

  const decodePath = (path) => decodeURIComponent(path).replace(/%20/g, ' ');

  const createPath = (index) => {
    return `/${pathArray.slice(0, index + 1).join('/')}`;
  };

  const getBreadcrumbName = (item, index) => {
    if (item === 'category') {
      return 'CATEGORY';
    }

    if (index > 0 && pathArray[index - 1] === 'category') {
      return decodePath(item); // This is the category name
    }

    const currentItem = portfolioData.find(entry => decodeURIComponent(entry.link) === decodePath(location.pathname));
    return currentItem && currentItem.breadcrumb ? currentItem.breadcrumb : decodePath(item);
  };

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">Home</Link>
        </li>
        {pathArray.map((item, index) => {
          const isLast = index === pathArray.length - 1;
          const link = createPath(index);
          const breadcrumbName = getBreadcrumbName(item, index);

          return (
            <React.Fragment key={index}>
              <span className="breadcrumb-divider">-</span>
              <li className={`breadcrumb-item ${isLast ? 'active' : ''}`}>
                {isLast ? (
                  breadcrumbName
                ) : (
                  <Link to={link}>{breadcrumbName}</Link>
                )}
              </li>
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
