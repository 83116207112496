// src/components/Header.js
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../icons/logo.svg';
import portfolioData from '../data/portfolioData';
import Button from './Button';
import './Header.css';

const Header = ({ toggleMenu, menuOpen, isScrolled, pageRef }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname;

  const currentItem = portfolioData.find(item => item.link === currentPath);
  const currentIndex = portfolioData.indexOf(currentItem);

  const prevItem = currentIndex > 0 ? portfolioData[currentIndex - 1] : null;
  const nextItem = currentIndex < portfolioData.length - 1 ? portfolioData[currentIndex + 1] : null;

  const noNav = currentPath === '/' || currentPath === '/privacy-policy' || currentPath === '/cookies-policy' || currentPath === '/404' || currentPath === '/about';

  const handleNavigation = (link) => {
    navigate(link);
    if (pageRef.current) {
      pageRef.current.scrollTo(0, 0); // Scroll the .page element back to the top
    }
  };

  return (
    <header className={`header ${isScrolled ? 'scrolled' : ''}`}>
      <h1>
        <Link to="/" className="logoIcon">
          <img className="logoIcon" src={Logo} alt="Troy Halliday" />
        </Link>
      </h1>
      <div className="nav-buttons">
        {!noNav && (
          <Button
            type="header"
            startIcon="previous"
            onClick={() => handleNavigation(prevItem ? prevItem.link : '#')}
            disabled={!prevItem}
          />
        )}
        {!noNav && (
          <Button
            type="header"
            startIcon="next"
            onClick={() => handleNavigation(nextItem ? nextItem.link : '#')}
            disabled={!nextItem}
          />
        )}
        <Button type="header" startIcon={menuOpen ? 'close' : 'menu'} onClick={toggleMenu} />
      </div>
    </header>
  );
};

export default Header;
