// src/pages/CategoryPage.js
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import PortfolioSection from '../components/PortfolioSection';
import StillInterested from '../components/StillInterested';

//import './categoryPage.css';

const CategoryPage = (categoryName) => {
  categoryName = categoryName.categoryName;
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  return (
    <div className="categoryPage">
      <PageHeader 
        title={categoryName}
        backgroundText='Category'
        showBreadcrumb={true}
      />
      <div className="page-content">
        <PortfolioSection category={decodeURIComponent(categoryName)} />
        <StillInterested  hasButton={true} />
      </div>
    </div>
  );
};

export default CategoryPage;
