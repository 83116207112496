// src/components/HeroSection.js
import React, { useState } from 'react';
import './HeroSection.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/zoom';
import { Zoom, Navigation, Pagination } from 'swiper/modules';
import defaultImage from '../images/image_unavailable.jpg';
import { useCurrentLocation } from '../utils/locationContext';
import { useCookies } from 'react-cookie';

const HeroSection = ({ images = [] }) => {
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const location = useCurrentLocation();
  const [cookies] = useCookies(["cookieConsent"]);

  const handleImageClick = (index) => {
      // Trigger GA4 event tracking
      if (window.gtag && cookies.cookieConsent) {
        //console.log('GA4 event:', window.gtag);
        window.gtag('event', 'image_click', {
          event_category: 'Hero Gallery Clicked',
          event_label: `Image ${index + 1} clicked`,
          page_path: location.pathname + location.search,
        });
      }

    setInitialSlide(index);
    setIsGalleryOpen(true);
  };

  const handleCloseGallery = (e) => {
    if (e.target.classList.contains('swiper-gallery-overlay')) {
      setIsGalleryOpen(false);
    }
  };

  const closeGallery = () => {
    setIsGalleryOpen(false);
  };

  return (
    <div className="hero-section">
      <div className="maskLeft"></div>
      <div className="maskRight"></div>
      <div className="hero-images-wrapper">
        {images.length === 1 ? (
          <div className="hero-image single-image">
            <div className="image-container" onClick={() => handleImageClick(0)}>
              <img src={images[0]?.src || defaultImage} alt={images[0]?.alt || 'Hero Image'} />
            </div>
          </div>
        ) : (
          <>
            <div className="hero-left">
              <div className="image-container" onClick={() => handleImageClick(0)}>
                <img src={images[0]?.src || defaultImage} alt={images[0]?.alt || 'Hero Image'} />
              </div>
            </div>
            <div className={`hero-right ${images.length > 2 ? 'hero-right-multi' : ''}`}>
              {images.slice(1, 5).map((image, index) => (
                <div key={index + 1} className="image-container" onClick={() => handleImageClick(index + 1)}>
                  <img src={image?.src || defaultImage} alt={image?.alt || 'Hero Image'} />
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {isGalleryOpen && (
        <div className="swiper-gallery-overlay active" onClick={handleCloseGallery}>
          <button className="close-button" onClick={closeGallery}>×</button>
          <Swiper
            initialSlide={initialSlide}
            modules={[Zoom, Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            navigation={true}
            zoom={true}
            pagination={{ clickable: true }}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-zoom-container">
                  <img src={image?.src || defaultImage} alt={image?.alt || 'Gallery Image'} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default HeroSection;
