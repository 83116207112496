import React from 'react';
import PageHeader from '../components/PageHeader';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {

  return (
    <>
      <PageHeader
        title='Privacy Policy'
        subtitle='Your privacy is important to me.'
        backgroundText='Privacy'
        showBreadcrumb={false}
      />
    <section className='privacy'>
        <div className='container'>
          <h1>Privacy Policy</h1>
          <p>Last Updated: 1st August 2024</p>
          <p>
            Thank you for visiting my portfolio website. Your privacy is important
            to me, and this Privacy Policy outlines how your information is
            collected, used, and protected when you visit this website.
          </p>

          <h2>1. Information I Collect</h2>
          <p>
            When you visit my website, certain information is automatically
            collected through cookies and similar technologies:
          </p>
          <ul>
            <li>
              <strong>Google Analytics Data:</strong> This includes anonymous data
              like your IP address, browser type, operating system, referring URLs,
              pages visited, and the time spent on those pages. This data helps me
              understand how visitors use the website, enabling me to improve the
              content and functionality.
            </li>
          </ul>

          <h2>2. How I Use Your Information</h2>
          <p>
            I use the information collected through Google Analytics for the
            following purposes:
          </p>
          <ul>
            <li>To analyze website traffic and visitor behavior.</li>
            <li>
              To improve the website’s layout, content, and functionality based on
              user interactions.
            </li>
            <li>
              To ensure the website runs smoothly and to enhance user experience.
            </li>
          </ul>

          <h2>3. Cookies</h2>
          <p>
            Cookies are small text files stored on your device when you visit the
            website. I use cookies to:
          </p>
          <ul>
            <li>
              Help track visitor interactions with the website through Google
              Analytics.
            </li>
            <li>Remember your preferences and optimize your browsing experience.</li>
          </ul>
          <p>
            You can choose to disable cookies via your browser settings, but this
            may impact your ability to fully use the website. For more detailed
            information about how cookies are used, please refer to our{' '}
            <a href='/cookies-policy'>Cookies Policy</a>.
          </p>

          <h2>4. Data Sharing</h2>
          <p>
            I do not share or sell your personal data to third parties. However,
            data collected through Google Analytics may be shared with Google, as
            outlined in their own privacy policies. No personally identifiable
            information is collected or stored.
          </p>

          <h2>5. Your Rights</h2>
          <p>
            You have the right to:
          </p>
          <ul>
            <li>Request access to any personal data I might hold about you.</li>
            <li>Ask for corrections to be made to inaccurate data.</li>
            <li>
              Request the deletion of any personal data I hold, where applicable.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact me at
            <a href="mailto:troy.halliday@gmail.com"> troy.halliday@gmail.com</a>.
          </p>

          <h2>6. Changes to This Privacy Policy</h2>
          <p>
            I may update this privacy policy occasionally. Any changes will be
            posted on this page, so please check back regularly.
          </p>

          <h2>7. Contact Information</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy, please
            contact me at
            <a href="mailto:troy.halliday@gmail.com"> troy.halliday@gmail.com</a>.
          </p>

        </div>
      </section>     
    </>

  );
};

export default PrivacyPolicy;
