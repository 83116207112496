import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/pageThumbnails/asite-branding-thumbnail.jpg';
import brandingExperiment from '../images/portfolioImages/Asite Branding Experiment.jpg';
import fileIconDesign from '../images/portfolioImages/Asite File Type Icon.jpg';
import iconDesigns from '../images/portfolioImages/Asite cBIM Icons.jpg';

const AsiteBranding = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Asite Branding' },
  ];

  const brandImages = [
    { src: brandingExperiment, alt: 'Asite Products Logo Design' }
  ];

  const fileImages = [
    { src: fileIconDesign, alt: 'File Icons Design' }
  ];

  const iconImages = [
    { src: iconDesigns, alt: 'cBIM Icon designs' }
  ];

  const skillList = ['Figma', 'Illustrator', 'Concept Development', 'Colour Theory', 'Typography', 'Brand Stratergy', 'Market Research'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h4>Product Logo Design</h4>
        <ExampleGallery images={brandImages} />
        <p>
          <span className='bold'>A Unified Visual Identity:</span> A series of product logos designed for Asite. This project involved creating a cohesive visual identity for Asite’s diverse product offerings, inspired by the distinctive style of their product, Openwage. Through this branding experiment, I aimed to enhance brand consistency and recognition across all Asite products. 
        </p>
        <p>
          <span className='bold'>Design Inspiration:</span> The inspiration for this logo series came from one of Asites producs Openwage, known for its sleek and modern aesthetic. My goal was to extend this visual language across all product logos, ensuring each one is easily identifiable as part of the Asite family while retaining its unique functionality.
        </p>
        <p>
          <span className='bold'>Colour & Typography:</span> The colour palette and typography were carefully selected to reflect Asite’s brand values of professionalism, innovation, and reliability. The consistent use of typography and a harmonious colour scheme creates a strong visual impact that resonates with the target audience.
        </p>
        <p>
          <span className='bold'>Iconography:</span> Each product logo features a unique icon that visually represents the product’s core functionality while adhering to the overall theme. The icons are designed to be simple yet powerful, ensuring clarity and immediate recognition.
        </p>
        <h5>Conclusion</h5>
        <p>
        This branding experiment for Asite’s product logos demonstrates my ability to create a unified and impactful visual identity. By leveraging the successful design elements of Openwage, I developed a series of logos that enhance brand recognition and cohesion. Explore the individual designs to see how each one contributes to a cohesive and compelling brand narrative.
        </p>

        <h4>Filetype Icons</h4>
        <ExampleGallery images={fileImages} />
        <p>
        The file type icons were designed to visually represent various document formats used within Asite's platform. Each icon is crafted to ensure clarity, immediate recognition, and a cohesive look across different file types.
        </p>
        <h5>Design Elemets</h5>
        <ul>
          <li><span className='bold'>Color Coding:</span>Each file type is assigned a distinct color to differentiate between formats quickly. This color-coding system helps users identify the file type at a glance.</li>
          <li><span className='bold'>Consistent Style:</span>The icons share a common visual style with a folded corner design, representing the file's physical appearance. This consistency helps maintain a unified aesthetic.</li>
          <li><span className='bold'>Iconography:</span>Simple and clear symbols are used to represent the file types, ensuring they are easily recognizable even at smaller sizes.</li>
        </ul>
        <h5>Conclusion</h5>
        <p>
        The file type icon series showcases my ability to design a consistent and intuitive icon set that enhances user experience. Each icon's clarity and cohesion contribute to a seamless interface within Asite's platform.
        </p>
        
        <h4>cBIM Custom Icons</h4>
        <ExampleGallery images={iconImages} />
        <p>
        The custom icons for Asite's cBIM system were designed to represent various tools and actions related to construction building information modeling. These icons aim to enhance the user interface by providing clear and intuitive visual cues for different functionalities.
        </p>
        <h5>Design Elements</h5>
        <ul>
          <li><span className='bold'>Monochromatic Style:</span> A monochromatic color scheme was chosen to maintain simplicity and focus on the icon's shape and meaning.</li>
          <li><span className='bold'>Geometric Precision:</span> The icons are designed with geometric precision to ensure clarity and scalability. This makes them versatile for use in different contexts and sizes.</li>
          <li><span className='bold'>Intuitive Symbols:</span> Each icon is carefully crafted to represent its function intuitively, helping users navigate the cBIM system efficiently.</li>
        </ul>
        <h5>My Approach</h5>
        <ul>
          <li><span className='bold'>Research and Ideation:</span>  Investigated common BIM tools and their visual representations. Brainstormed and sketched initial ideas.</li>
          <li><span className='bold'>Wireframing and Prototyping:</span> Developed wireframes and prototypes to visualize the icons' functions and refine their designs.</li>
          <li><span className='bold'>User Testing and Iteration:</span> Feedback from user testing guided the iterative process, ensuring the icons were functional and easy to understand.</li>
        </ul>
        <h5>Conclusion</h5>
        <p>
        The cBIM custom icon series demonstrates my ability to create a clear and functional visual language for complex systems. These icons improve the user experience by providing intuitive and recognizable symbols for various BIM tools and actions.
        </p>
    </PortfolioLayout>
  );
};

export default AsiteBranding
