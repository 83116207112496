// src/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <p>© 2024 Troy Halliday, All rights reserved. <a href='/privacy-policy'>Privacy Policy</a>, <a href='/cookies-policy'>Cookies Policy</a>.</p>
    </footer>
  );
};

export default Footer;
