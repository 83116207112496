// src/components/VideoPlayer.js
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import Icon from './Icon';
import './VideoPlayer.css';

const VideoPlayer = ({ url, poster }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div className="playerWrapper">
      {!isPlaying && (
        <div className="posterWrapper" onClick={handlePlay}>
          <img src={poster} alt="Poster" className="posterImage" />
          <div className="playButton">
            <Icon name="play" className="playIcon" />
          </div>
        </div>
      )}
      <ReactPlayer
        url={url}
        playing={isPlaying}
        controls
        className="playerWrapper"
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default VideoPlayer;

