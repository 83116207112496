import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import './StillInterested.css';

const StillInterested = ({ hasButton = false, ...props }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const pageElement = document.querySelector('.page');
    if (pageElement) {
      pageElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
    navigate('/about');
  };
  
  return (
    <section className='stillInterested' {...props}>
      <div className='bannerSection'>
        <div className='content'>
          <h3>Still Interested?</h3>
          <p>Find out more...</p>
        </div>
      </div>

      {hasButton && 
        <div className='buttonContainer'>
          <Button type='primary' size='large' onClick={handleButtonClick}>About me</Button>
        </div>
      }
    </section>
  );
}

export default StillInterested;
