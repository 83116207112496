import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';
import VideoPlayer from '../components/VideoPlayer';

import heroImage1 from '../images/wiQBranding/wi-qBranding1.jpg';
import heroImage2 from '../images/wiQBranding/wi-qBranding2.jpg';
import heroImage3 from '../images/wiQBranding/wi-qBranding3.jpg';
import heroImage4 from '../images/wiQBranding/wi-qBranding4.jpg';
import heroImage5 from '../images/wiQBranding/wi-qBranding5.jpg';

import brandingImage1 from '../images/wiQBranding/wi-qBranding6.jpg';

import videoFile1 from '../images/wiQBranding/wiqPromo.mp4';
import videoFile2 from '../images/wiQBranding/wiqShaping.mp4';

import wiqShaping from '../images/wiQBranding/wiqShaping.jpg';



const WiqBranding = () => {
  const heroImages = [
    { src: heroImage1, alt: 'wi-Q Marketing' },
    { src: heroImage2, alt: 'wi-Q Marketing' },
    { src: heroImage3, alt: 'wi-Q Marketing' },
    { src: heroImage4, alt: 'wi-Q Marketing' },
    { src: heroImage5, alt: 'wi-Q Marketing' }
  ];
   
    const brandingImage = [
        { src: brandingImage1, alt: 'wi-Q Branding' }
    ];

    const skillList = ['Adobe XD', 'Illustration', 'In-Design', 'Logo Design', 'Photoshop', 'Print ready artwork'];

  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h4>About</h4>
        <p>
            As part of wi-Q business growth in 2021 and new product offerings the corporate branding needed to be refreshed. The companies new product logos were created to be clearly identified as a part of the wi-Q brand.
        </p>
        <ExampleGallery images={brandingImage} />
        <h4>Marketing</h4>
        <p>
            As part of the companies ongoing growth, I was given the responsibility of creating key marketing and promotional videos as well as a series of in house training manuals. Created using After Effects, 3D Elements and stock assets the video was animated to achieve the best results with a minimal budget.
        </p>
        <VideoPlayer url={videoFile1} poster={heroImage1}/>
        <VideoPlayer url={videoFile2} poster={wiqShaping}/>

    </PortfolioLayout>
  );
};

export default WiqBranding
