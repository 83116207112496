import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/illegalAliens/illegal-aliens-sketch-book.jpg';
import heroImage2 from '../images/illegalAliens/fastest-man-alive-mockup.jpg';

import galleryImage1 from '../images/illegalAliens/Iron-Alien-Comic-1400x900.jpg';
import galleryImage2 from '../images/illegalAliens/IA-Flash-Rebirth-wallpaper-1440x900px.jpg';
import galleryImage3 from '../images/illegalAliens/Super-Alien-Lantern-Wallpaper-1440x900.jpg';

const IllegalAliensIllustrations = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Hero Image 1' },
    { src: heroImage2, alt: 'Hero Image 2' }
  ];

  const galleryImages = [
    { src: galleryImage1, alt: 'Iron Alien Comic' },
    { src: galleryImage2, alt: 'Flash Rebirth Wallpaper' },
    { src: galleryImage3, alt: 'Super Alien Lantern Wallpaper' }
  ];

  const skillList = ['Photoshop', 'Illustrator', 'Character Development'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
      <h4>Overview</h4>
      <p>
      The "Illegal Aliens" project required comic book-style illustrations for desktop wallpaper and promotional purposes. This fun project involved creating visually captivating illustrations that parodied existing comic book characters.
      </p>
      <ExampleGallery images={galleryImages} />
      <h4>Key Achievements</h4>
      <ul>
        <li><span className='bold'>Comic Book Style:</span> Designed illustrations to mirror comic book cover art, ensuring a familiar yet unique appeal.</li>
        <li><span className='bold'>Mixed Media Approach:</span>Utilized 3D Studio Max for modeling, Photoshop for texturing, and Illustrator for vector elements.</li>
        <li><span className='bold'></span></li> 
      </ul>

      <h4>My Approach</h4>
      <h5>Research and drafting:</h5>
      <ul>
        <li>Conducted thorough research and created multiple rough drafts based on comic book art.</li>
        <li>Incorporated client feedback to refine and finalize the illustrations.</li>
      </ul>

      <h5>Finalisation:</h5>
      <ul>
        <li>Delivered high-quality illustrations ready for desktop wallpapers and promotional materials.</li>
      </ul>

      <h4>Conclusion</h4>
      <p>
      A fun customer project giving me the ability to dust off some old skillsets, blending traditional comic book aesthetics with modern digital tools. By leveraging a combination of 3D Studio Max, Photoshop, and Illustrator, I was able to create detailed and visually appealing illustrations that met the client's needs for both promotional and desktop wallpaper purposes. This project underscores my skills in artistic adaptation, client collaboration, and iterative design, ensuring the final product was both high-quality and aligned with the client’s vision.

      </p>
    </PortfolioLayout>
  );
};

export default IllegalAliensIllustrations

