import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/bmwHeadunit/bmwHeadunit1.jpg';

import galleryImage1 from '../images/bmwHeadunit/bmwHeadunit2.jpg';
import galleryImage2 from '../images/bmwHeadunit/bmwHeadunit3.jpg';
import galleryImage3 from '../images/bmwHeadunit/bmwHeadunit4.jpg';
import galleryImage4 from '../images/bmwHeadunit/bmwHeadunit5.jpg';
import galleryImage5 from '../images/bmwHeadunit/bmwHeadunit6.jpg';
import galleryImage6 from '../images/bmwHeadunit/bmwHeadunit7.jpg';
import galleryImage7 from '../images/bmwHeadunit/bmwHeadunit8.jpg';
import galleryImage8 from '../images/bmwHeadunit/bmwHeadunit9.jpg';
import galleryImage9 from '../images/bmwHeadunit/bmwHeadunit10.jpg';
import galleryImage10 from '../images/bmwHeadunit/bmwHeadunit11.jpg';




const BMWHeadunit = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Asite Branding' },
  ];

  const galleryImages = [
    { src: galleryImage1, alt: 'BMW Headunit' },
    { src: galleryImage2, alt: 'BMW Headunit' },
    { src: galleryImage3, alt: 'BMW Headunit' },
    { src: galleryImage4, alt: 'BMW Headunit' },
    { src: galleryImage5, alt: 'BMW Headunit' },
    { src: galleryImage6, alt: 'BMW Headunit' },
    { src: galleryImage7, alt: 'BMW Headunit' },
    { src: galleryImage8, alt: 'BMW Headunit' },
    { src: galleryImage9, alt: 'BMW Headunit' },
    { src: galleryImage10, alt: 'BMW Headunit' },
  ];

  const skillList = ['Figma', 'Illustrator', 'Concept Development', 'Colour Theory', 'Typography', 'Brand Stratergy', 'Market Research'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h5>Overview</h5>
        <p>
        This section highlights my work on designing a BMW-themed UI interface for an aftermarket Android touch screen and BMW drive interface. The project aimed to create a seamless and immersive experience that reflected the essence of BMW's renowned driving experience while integrating with advanced Android touch screen technology.
        </p>
        <ExampleGallery images={galleryImages} />
        <h5>My Approach</h5>
        <p>
        I began by thoroughly researching BMW's design language and user interface elements. This included studying BMW's existing in-car interfaces, understanding user expectations, and identifying opportunities for integration with Android technology.
        </p>
        <h5>Key Features:</h5>
        <ul>
          <li><span className='bold'>Brand consistency:</span>  I successfully captured the distinctive visual elements and design language of BMW, ensuring a consistent brand experience throughout the interface. By incorporating iconic BMW design elements, colours, and typography, I created an interface that instantly resonated with BMW enthusiasts and conveyed a sense of luxury and sophistication.</li>
          <li><span className='bold'>Intuitive User Experience:</span> User experience was at the forefront of my design approach. I focused on creating an intuitive and easy-to-navigate interface that aligned with the existing BMW drive interface. The design layout, icons, and controls were carefully crafted to provide a seamless transition from the BMW's original interface to the aftermarket Android touch screen, allowing users to effortlessly access features and controls while on the road.</li>
          <li><span className='bold'>Enhanced Functionality:</span> Alongside the aesthetic considerations, I also aimed to enhance the functionality of the interface. By integrating advanced features such as real-time vehicle information display, navigation, multimedia control, and voice-activated commands, I provided users with a comprehensive and highly functional interface that complemented the driving experience and offered an elevated level of convenience and control.</li>
          <li><span className='bold'>Customisation Options:</span> Recognising the importance of personalisation, I incorporated customisation options that allowed users to tailor the interface to their preferences. From colour themes and layout configurations to widget placement and shortcut settings, users could customise the interface to suit their individual needs and enhance their driving experience.</li>
        </ul>
        
        <h5>Conclusion</h5>
        <p>
          The BMW-themed Android head unit project showcases my ability to blend brand aesthetics with advanced technology, creating a user-friendly and visually appealing interface. This project not only reinforced brand consistency but also enhanced the driving experience through improved functionality and customization options.
        </p>
    </PortfolioLayout>
  );
};

export default BMWHeadunit
