import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/jobs365/jobs1.jpg';
import heroImage2 from '../images/jobs365/jobs2.jpg';

import galleryImage1 from '../images/jobs365/jobs3.jpg';
import galleryImage2 from '../images/jobs365/jobs4.jpg';
import galleryImage3 from '../images/jobs365/jobs5.jpg';




const Jobs365 = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Asite Branding' },
    { src: heroImage2, alt: 'Asite Branding' },
  ];

  const galleryImages = [
    { src: galleryImage1, alt: 'Jobs365' },
    { src: galleryImage2, alt: 'Jobs365' },
    { src: galleryImage3, alt: 'Jobs365' },
  ];

  const skillList = ['Photoshop', 'Illustrator', 'Typography', 'HTML', 'CSS', 'JavaScript', 'PHP', 'UX/UI Design', 'Wireframing', 'Prototyping', 'Usability Testing'];


  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList}>
        <h4>Overview</h4>
        <p>
        This section highlights my work on designing and developing a mobile-optimized recruitment website for Jobs 365. The project aimed to create a user-friendly platform for job seekers and employers, ensuring a seamless and efficient experience across all mobile devices.
        </p>
        <ExampleGallery images={galleryImages} />

        <h5>Key Achievements:</h5>
        <ul>
          <li><span className='bold'>Responsive Design:</span> Ensured that the website provided an optimal viewing experience across a wide range of devices, from smartphones to tablets. The responsive design allows for easy navigation and interaction, regardless of screen size.</li>
          <li><span className='bold'>Intuitive User Experience:</span> Focused on creating an intuitive and easy-to-navigate interface that allows users to effortlessly search for jobs, submit applications, and manage their profiles. The user experience was enhanced through simplified layouts, clear call-to-action buttons, and streamlined forms.</li>
          <li><span className='bold'>Advanced Job Search and Filters:</span> Developed advanced search and filter functionalities that enable job seekers to find relevant job listings quickly. Users can filter jobs by location, industry, job type, and more, providing a personalized job search experience.</li>
          <li><span className='bold'>Employer Dashboard:</span> Designed a dedicated dashboard for employers to post job listings, manage applications, and track candidate profiles. The employer dashboard offers a user-friendly interface with intuitive controls for easy job management.</li>
          <li><span className='bold'>Real-Time Notification:</span>Implemented real-time notifications to keep users updated on new job postings, application statuses, and messages from employers. This feature ensures that both job seekers and employers stay informed and engaged.</li>
        </ul>

        <h4>My Approach</h4>
        <h5>Research and Ideation:</h5>
        <p>
        Conducted extensive research to understand the needs of both job seekers and employers. This involved competitor analysis, user interviews, and usability testing to gather insights and identify key requirements.
        </p>
        <h5>Wireframing and Prototyping:</h5>
        <p>
        Developed wireframes and interactive prototypes to visualize the website layout and functionality. Photoshop was used to create detailed mockups and gather feedback from stakeholders.
        </p>
        <h5>User Testing and iteration</h5>
        <p>
        Conducted multiple rounds of user testing to gather feedback on the design and functionality. Iterative design processes were employed to refine the website based on user insights and ensure a smooth user experience.
        </p>
        <h5>Development and Launch:</h5>
        <p>
          The development phase involved translating the design into a fully functional website using HTML, CSS, JavaScript and PHP. I developed this around a series of plugins that I had developed for Wordpress. The website was rigorously tested for responsiveness, performance, and usability before the official launch.
        </p>

        <h4>Conclusion</h4> 
        <p>
        The Jobs 365 mobile-optimized recruitment website project showcases my ability to create a user-centric and functional platform that meets the needs of job seekers and employers. Through responsive design, advanced search functionalities, and intuitive user experience, the website enhances the recruitment process and provides a seamless experience across all mobile devices.
        </p>

        
    </PortfolioLayout>
  );
};

export default Jobs365
