import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageHeader from '../components/PageHeader';
import PortfolioSection from '../components/PortfolioSection';
import StillInterested from '../components/StillInterested';
import Button from '../components/Button';

import './Home.css';

const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Adding a delay to ensure the DOM is fully rendered
    }
  }, [location]);

  return (
    <>
      <PageHeader 
        title='Principle/Lead Product Designer'
        backgroundText='Welcome'
        showBreadcrumb={false}
      />

      <section className='introduction'>
        <div className='introductionContent'>
          <p>Hi, I'm Troy Halliday. I'm a Principle/Lead Product Designer with over 20 years of experience in the design industry. I have a passion for creating beautiful and functional designs that are user-friendly and accessible. I have worked with a wide range of clients, from small startups to large corporations, and I am always looking for new challenges and opportunities to grow as a designer.</p>
        </div>
      </section>

      <PortfolioSection />
      <StillInterested hasButton={true} />

      <PageHeader
        id='contact'
        section={true}
        title='Why not get in touch?'
        backgroundText='Contact Me'
        showBreadcrumb={false}
      />
      <section className='contactDetails'>
        <Button type='link' link='mailto:troy.halliday@gmail.com' startIcon='emailSolid'>troy.halliday@gmail.com</Button>
        <Button type='link' link='https://www.linkedin.com/in/troyhalliday/' startIcon='linkedInSolid'>/troyhalliday</Button>
      </section>
    </>
  );
};

export default Home;
