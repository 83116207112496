import React from 'react';
import PortfolioLayout from '../components/PortfolioLayout';
import ExampleGallery from '../components/ExampleGallery';

import heroImage1 from '../images/stockManagement/StockManagementDesktop.jpg';
import heroImage2 from '../images/stockManagement/aStock2.jpg';

import galleryImage1 from '../images/stockManagement/smMockupScreens.jpg';
import galleryImage2 from '../images/stockManagement/AsiteMarketplaceInventory1.jpg';
import galleryImage3 from '../images/stockManagement/AsiteMarketplaceInventory2.jpg';
import galleryImage4 from '../images/stockManagement/AsiteMarketplaceInventory3.jpg';
import galleryImage5 from '../images/stockManagement/AsiteMarketplaceInventory4.jpg';
import galleryImage6 from '../images/stockManagement/AsiteMarketplaceInventory5.jpg';
import galleryImage7 from '../images/stockManagement/AsiteMarketplaceInventory6.jpg';


const AStock = () => {
  const heroImages = [
    { src: heroImage1, alt: 'Asite Stock Managemet' },
    { src: heroImage2, alt: 'Asite Stock Managemet' }
  ];

  const brandingImages = [
    { src: galleryImage2, alt: 'aStock App' },
    { src: galleryImage3, alt: 'aStock App'},
    { src: galleryImage4, alt: 'aStock App' },
    { src: galleryImage5, alt: 'aStock App' },
    { src: galleryImage6, alt: 'aStock App' },
    { src: galleryImage7, alt: 'aStock App' }
  ];

  const brandingImages2 = [
    { src: galleryImage1, alt: 'aStock App' }
  ];

  const skillList = ['Figma', 'UX Design', 'UI Design', 'Prototyping', 'User Research', 'Competitive Analysis', 'Project Management', 'React-Native', 'API Integration'];

  return (
    <PortfolioLayout heroImages={heroImages} skills={skillList} >
      <h4>Introduction</h4>
      <p>
        This section highlights my work on designing and developing an inventory/stock management extension for the Asite Marketplace web application. This project stemmed from a direct client request to add an inventory management system to complement the existing procurement phases we had completed. The goal was to create a solution that seamlessly integrated with our marketplace workflow, automating processes and streamlining operations.
      </p>
      <ExampleGallery images={brandingImages} />
      <h4>Key Features</h4>
      <h5>Web Application for Admin Staff:</h5>
      <ul>
        <li>
          <span className='bold'>Inventory Tracking:</span> Real-time tracking of stock levels, automated alerts for low stock, and comprehensive reporting tools.
        </li>
        <li>
          <span className='bold'>Procurement Integration:</span> Seamless integration with the procurement process, maintaining data consistency across all phases.
        </li>
        <li>
          <span className='bold'>User Permissions: </span> Role-based access control, ensuring secure data management and operational efficiency.
        </li>
      </ul>
      <h5>Mobile Application for Warehouse Personnel:</h5>
      <ul>
        <li>
          <span className='bold'>Order Picking and Packing:</span> Streamlined processes for picking and packing orders, reducing errors and improving efficiency.
        </li>
        <li>
          <span className='bold'>Inventory Queries:</span> Quick access to inventory data, enabling immediate responses to stock inquiries.
        </li>
        <li>
          <span className='bold'>Stocktakes:</span> Easy execution of stocktakes, ensuring accurate inventory records.
        </li>
      </ul>
      <h4>My Approach</h4>
      <h5>Research and Ideation</h5>
      <p>
        The project began with a request from a client to add an inventory management system following the completion of our procurement workflow. After speaking with the client to understand their needs, I conducted extensive research and created initial flow charts to conceptualize the stock management system.
      </p>

      <h5>Proof of Concept (POC):</h5>
      <p>
        Developed a POC to demonstrate the feasibility of integrating the inventory management system with our existing marketplace workflow. This included designing both the web and mobile applications, focusing on usability and seamless integration.
      </p>
      <ExampleGallery images={brandingImages2} />

      <h5>Stakeholder Presentation:</h5>
      <p>
      Presented the POC to shareholders, receiving unanimous approval to proceed with the full design and development. The stakeholders appreciated the thorough research and the potential value the system would bring to the Asite Marketplace.
      </p>
      <h5>Development and Implementation:</h5>
      <p>
        The development phase is just about to begin. The web application will be developed by our in-house team, while the mobile application will be proliminarily developed by myself in React-Native and API's will be created by our team to tie the systems together. The goal is to have both applications fully integrated and operational in a truncated timeline.
      </p>
      <h4>Conclusion</h4>
      <p>
        The Inventory/Stock Management extension project for the Asite Marketplace exemplifies my ability to analyze and recognize potential areas of growth for the company. By identifying the client's need for a seamless inventory management system and envisioning its integration with our existing procurement workflow, I was able to propose a valuable enhancement to our platform.
      </p>
      <p>
        My approach involved thorough research, iterative design processes, and close collaboration with stakeholders and the development team. This ensured that the solution was not only technically feasible but also aligned with user needs and business goals.
      </p>
      <p>
        Additionally, my ability to present the concept and proof of concept to both the client and the board with confidence and effectiveness played a crucial role in securing unanimous approval from the shareholders. This project underscores my strengths in strategic thinking, user-centric design, and persuasive communication, all of which are essential for driving innovation and growth within the company.
      </p>


      <h4>Next Steps</h4>
        <p>
          <span className='bold'>Mobile App Development:</span> 
          I have begun developing the mobile application in React-Native, focusing on an offline-first methodology, user-friendly interfaces and seamless integration with the web application.
        </p>

    </PortfolioLayout>
  );
};

export default AStock

